const e = "undefined" != typeof globalThis ? globalThis : "undefined" != typeof self ? self : "undefined" != typeof window ? window : global,
  t = Object.keys,
  n = Array.isArray;
function r(e, n) {
  return "object" != typeof n || t(n).forEach(function (t) {
    e[t] = n[t];
  }), e;
}
"undefined" == typeof Promise || e.Promise || (e.Promise = Promise);
const s = Object.getPrototypeOf,
  i = {}.hasOwnProperty;
function o(e, t) {
  return i.call(e, t);
}
function a(e, n) {
  "function" == typeof n && (n = n(s(e))), ("undefined" == typeof Reflect ? t : Reflect.ownKeys)(n).forEach(t => {
    l(e, t, n[t]);
  });
}
const u = Object.defineProperty;
function l(e, t, n, s) {
  u(e, t, r(n && o(n, "get") && "function" == typeof n.get ? {
    get: n.get,
    set: n.set,
    configurable: !0
  } : {
    value: n,
    configurable: !0,
    writable: !0
  }, s));
}
function c(e) {
  return {
    from: function (t) {
      return e.prototype = Object.create(t.prototype), l(e.prototype, "constructor", e), {
        extend: a.bind(null, e.prototype)
      };
    }
  };
}
const h = Object.getOwnPropertyDescriptor;
function d(e, t) {
  let n;
  return h(e, t) || (n = s(e)) && d(n, t);
}
const f = [].slice;
function p(e, t, n) {
  return f.call(e, t, n);
}
function y(e, t) {
  return t(e);
}
function m(e) {
  if (!e) throw new Error("Assertion Failed");
}
function v(t) {
  e.setImmediate ? setImmediate(t) : setTimeout(t, 0);
}
function g(e, t) {
  return e.reduce((e, n, r) => {
    var s = t(n, r);
    return s && (e[s[0]] = s[1]), e;
  }, {});
}
function b(e, t) {
  if ("string" == typeof t && o(e, t)) return e[t];
  if (!t) return e;
  if ("string" != typeof t) {
    for (var n = [], r = 0, s = t.length; r < s; ++r) {
      var i = b(e, t[r]);
      n.push(i);
    }
    return n;
  }
  var a = t.indexOf(".");
  if (-1 !== a) {
    var u = e[t.substr(0, a)];
    return null == u ? void 0 : b(u, t.substr(a + 1));
  }
}
function _(e, t, r) {
  if (e && void 0 !== t && (!("isFrozen" in Object) || !Object.isFrozen(e))) if ("string" != typeof t && "length" in t) {
    m("string" != typeof r && "length" in r);
    for (var s = 0, i = t.length; s < i; ++s) _(e, t[s], r[s]);
  } else {
    var a = t.indexOf(".");
    if (-1 !== a) {
      var u = t.substr(0, a),
        l = t.substr(a + 1);
      if ("" === l) void 0 === r ? n(e) && !isNaN(parseInt(u)) ? e.splice(u, 1) : delete e[u] : e[u] = r;else {
        var c = e[u];
        c && o(e, u) || (c = e[u] = {}), _(c, l, r);
      }
    } else void 0 === r ? n(e) && !isNaN(parseInt(t)) ? e.splice(t, 1) : delete e[t] : e[t] = r;
  }
}
function w(e) {
  var t = {};
  for (var n in e) o(e, n) && (t[n] = e[n]);
  return t;
}
const x = [].concat;
function k(e) {
  return x.apply([], e);
}
const E = "BigUint64Array,BigInt64Array,Array,Boolean,String,Date,RegExp,Blob,File,FileList,FileSystemFileHandle,FileSystemDirectoryHandle,ArrayBuffer,DataView,Uint8ClampedArray,ImageBitmap,ImageData,Map,Set,CryptoKey".split(",").concat(k([8, 16, 32, 64].map(e => ["Int", "Uint", "Float"].map(t => t + e + "Array")))).filter(t => e[t]),
  P = E.map(t => e[t]);
g(E, e => [e, !0]);
let K = null;
function O(e) {
  K = "undefined" != typeof WeakMap && new WeakMap();
  const t = S(e);
  return K = null, t;
}
function S(e) {
  if (!e || "object" != typeof e) return e;
  let t = K && K.get(e);
  if (t) return t;
  if (n(e)) {
    t = [], K && K.set(e, t);
    for (var r = 0, i = e.length; r < i; ++r) t.push(S(e[r]));
  } else if (P.indexOf(e.constructor) >= 0) t = e;else {
    const n = s(e);
    for (var a in t = n === Object.prototype ? {} : Object.create(n), K && K.set(e, t), e) o(e, a) && (t[a] = S(e[a]));
  }
  return t;
}
const {
  toString: A
} = {};
function C(e) {
  return A.call(e).slice(8, -1);
}
const j = "undefined" != typeof Symbol ? Symbol.iterator : "@@iterator",
  D = "symbol" == typeof j ? function (e) {
    var t;
    return null != e && (t = e[j]) && t.apply(e);
  } : function () {
    return null;
  },
  I = {};
function B(e) {
  var t, r, s, i;
  if (1 === arguments.length) {
    if (n(e)) return e.slice();
    if (this === I && "string" == typeof e) return [e];
    if (i = D(e)) {
      for (r = []; !(s = i.next()).done;) r.push(s.value);
      return r;
    }
    if (null == e) return [e];
    if ("number" == typeof (t = e.length)) {
      for (r = new Array(t); t--;) r[t] = e[t];
      return r;
    }
    return [e];
  }
  for (t = arguments.length, r = new Array(t); t--;) r[t] = arguments[t];
  return r;
}
const T = "undefined" != typeof Symbol ? e => "AsyncFunction" === e[Symbol.toStringTag] : () => !1;
var R = "undefined" != typeof location && /^(http|https):\/\/(localhost|127\.0\.0\.1)/.test(location.href);
function F(e, t) {
  R = e, M = t;
}
var M = () => !0;
const N = !new Error("").stack;
function q() {
  if (N) try {
    throw q.arguments, new Error();
  } catch (e) {
    return e;
  }
  return new Error();
}
function $(e, t) {
  var n = e.stack;
  return n ? (t = t || 0, 0 === n.indexOf(e.name) && (t += (e.name + e.message).split("\n").length), n.split("\n").slice(t).filter(M).map(e => "\n" + e).join("")) : "";
}
var U = ["Unknown", "Constraint", "Data", "TransactionInactive", "ReadOnly", "Version", "NotFound", "InvalidState", "InvalidAccess", "Abort", "Timeout", "QuotaExceeded", "Syntax", "DataClone"],
  L = ["Modify", "Bulk", "OpenFailed", "VersionChange", "Schema", "Upgrade", "InvalidTable", "MissingAPI", "NoSuchDatabase", "InvalidArgument", "SubTransaction", "Unsupported", "Internal", "DatabaseClosed", "PrematureCommit", "ForeignAwait"].concat(U),
  V = {
    VersionChanged: "Database version changed by other database connection",
    DatabaseClosed: "Database has been closed",
    Abort: "Transaction aborted",
    TransactionInactive: "Transaction has already completed or failed",
    MissingAPI: "IndexedDB API missing. Please visit https://tinyurl.com/y2uuvskb"
  };
function W(e, t) {
  this._e = q(), this.name = e, this.message = t;
}
function Y(e, t) {
  return e + ". Errors: " + Object.keys(t).map(e => t[e].toString()).filter((e, t, n) => n.indexOf(e) === t).join("\n");
}
function z(e, t, n, r) {
  this._e = q(), this.failures = t, this.failedKeys = r, this.successCount = n, this.message = Y(e, t);
}
function G(e, t) {
  this._e = q(), this.name = "BulkError", this.failures = Object.keys(t).map(e => t[e]), this.failuresByPos = t, this.message = Y(e, t);
}
c(W).from(Error).extend({
  stack: {
    get: function () {
      return this._stack || (this._stack = this.name + ": " + this.message + $(this._e, 2));
    }
  },
  toString: function () {
    return this.name + ": " + this.message;
  }
}), c(z).from(W), c(G).from(W);
var H = L.reduce((e, t) => (e[t] = t + "Error", e), {});
const Q = W;
var X = L.reduce((e, t) => {
  var n = t + "Error";
  function r(e, r) {
    this._e = q(), this.name = n, e ? "string" == typeof e ? (this.message = `${e}${r ? "\n " + r : ""}`, this.inner = r || null) : "object" == typeof e && (this.message = `${e.name} ${e.message}`, this.inner = e) : (this.message = V[t] || n, this.inner = null);
  }
  return c(r).from(Q), e[t] = r, e;
}, {});
X.Syntax = SyntaxError, X.Type = TypeError, X.Range = RangeError;
var J = U.reduce((e, t) => (e[t + "Error"] = X[t], e), {});
var Z = L.reduce((e, t) => (-1 === ["Syntax", "Type", "Range"].indexOf(t) && (e[t + "Error"] = X[t]), e), {});
function ee() {}
function te(e) {
  return e;
}
function ne(e, t) {
  return null == e || e === te ? t : function (n) {
    return t(e(n));
  };
}
function re(e, t) {
  return function () {
    e.apply(this, arguments), t.apply(this, arguments);
  };
}
function se(e, t) {
  return e === ee ? t : function () {
    var n = e.apply(this, arguments);
    void 0 !== n && (arguments[0] = n);
    var r = this.onsuccess,
      s = this.onerror;
    this.onsuccess = null, this.onerror = null;
    var i = t.apply(this, arguments);
    return r && (this.onsuccess = this.onsuccess ? re(r, this.onsuccess) : r), s && (this.onerror = this.onerror ? re(s, this.onerror) : s), void 0 !== i ? i : n;
  };
}
function ie(e, t) {
  return e === ee ? t : function () {
    e.apply(this, arguments);
    var n = this.onsuccess,
      r = this.onerror;
    this.onsuccess = this.onerror = null, t.apply(this, arguments), n && (this.onsuccess = this.onsuccess ? re(n, this.onsuccess) : n), r && (this.onerror = this.onerror ? re(r, this.onerror) : r);
  };
}
function oe(e, t) {
  return e === ee ? t : function (n) {
    var s = e.apply(this, arguments);
    r(n, s);
    var i = this.onsuccess,
      o = this.onerror;
    this.onsuccess = null, this.onerror = null;
    var a = t.apply(this, arguments);
    return i && (this.onsuccess = this.onsuccess ? re(i, this.onsuccess) : i), o && (this.onerror = this.onerror ? re(o, this.onerror) : o), void 0 === s ? void 0 === a ? void 0 : a : r(s, a);
  };
}
function ae(e, t) {
  return e === ee ? t : function () {
    return !1 !== t.apply(this, arguments) && e.apply(this, arguments);
  };
}
function ue(e, t) {
  return e === ee ? t : function () {
    var n = e.apply(this, arguments);
    if (n && "function" == typeof n.then) {
      for (var r = this, s = arguments.length, i = new Array(s); s--;) i[s] = arguments[s];
      return n.then(function () {
        return t.apply(r, i);
      });
    }
    return t.apply(this, arguments);
  };
}
Z.ModifyError = z, Z.DexieError = W, Z.BulkError = G;
var le = {};
const ce = 100,
  [he, de, fe] = "undefined" == typeof Promise ? [] : (() => {
    let e = Promise.resolve();
    if ("undefined" == typeof crypto || !crypto.subtle) return [e, s(e), e];
    const t = crypto.subtle.digest("SHA-512", new Uint8Array([0]));
    return [t, s(t), e];
  })(),
  pe = de && de.then,
  ye = he && he.constructor,
  me = !!fe;
var ve = !1,
  ge = fe ? () => {
    fe.then($e);
  } : e.setImmediate ? setImmediate.bind(null, $e) : e.MutationObserver ? () => {
    var e = document.createElement("div");
    new MutationObserver(() => {
      $e(), e = null;
    }).observe(e, {
      attributes: !0
    }), e.setAttribute("i", "1");
  } : () => {
    setTimeout($e, 0);
  },
  be = function (e, t) {
    Se.push([e, t]), we && (ge(), we = !1);
  },
  _e = !0,
  we = !0,
  xe = [],
  ke = [],
  Ee = null,
  Pe = te,
  Ke = {
    id: "global",
    global: !0,
    ref: 0,
    unhandleds: [],
    onunhandled: dt,
    pgp: !1,
    env: {},
    finalize: function () {
      this.unhandleds.forEach(e => {
        try {
          dt(e[0], e[1]);
        } catch (e) {}
      });
    }
  },
  Oe = Ke,
  Se = [],
  Ae = 0,
  Ce = [];
function je(e) {
  if ("object" != typeof this) throw new TypeError("Promises must be constructed via new");
  this._listeners = [], this.onuncatched = ee, this._lib = !1;
  var t = this._PSD = Oe;
  if (R && (this._stackHolder = q(), this._prev = null, this._numPrev = 0), "function" != typeof e) {
    if (e !== le) throw new TypeError("Not a function");
    return this._state = arguments[1], this._value = arguments[2], void (!1 === this._state && Te(this, this._value));
  }
  this._state = null, this._value = null, ++t.ref, Be(this, e);
}
const De = {
  get: function () {
    var e = Oe,
      t = Xe;
    function n(n, r) {
      var s = !e.global && (e !== Oe || t !== Xe);
      const i = s && !tt();
      var o = new je((t, o) => {
        Fe(this, new Ie(lt(n, e, s, i), lt(r, e, s, i), t, o, e));
      });
      return R && qe(o, this), o;
    }
    return n.prototype = le, n;
  },
  set: function (e) {
    l(this, "then", e && e.prototype === le ? De : {
      get: function () {
        return e;
      },
      set: De.set
    });
  }
};
function Ie(e, t, n, r, s) {
  this.onFulfilled = "function" == typeof e ? e : null, this.onRejected = "function" == typeof t ? t : null, this.resolve = n, this.reject = r, this.psd = s;
}
function Be(e, t) {
  try {
    t(t => {
      if (null === e._state) {
        if (t === e) throw new TypeError("A promise cannot be resolved with itself.");
        var n = e._lib && Ue();
        t && "function" == typeof t.then ? Be(e, (e, n) => {
          t instanceof je ? t._then(e, n) : t.then(e, n);
        }) : (e._state = !0, e._value = t, Re(e)), n && Le();
      }
    }, Te.bind(null, e));
  } catch (t) {
    Te(e, t);
  }
}
function Te(e, t) {
  if (ke.push(t), null === e._state) {
    var n = e._lib && Ue();
    t = Pe(t), e._state = !1, e._value = t, R && null !== t && "object" == typeof t && !t._promise && function (e, t, n) {
      try {
        e.apply(null, n);
      } catch (e) {
        t && t(e);
      }
    }(() => {
      var n = d(t, "stack");
      t._promise = e, l(t, "stack", {
        get: () => ve ? n && (n.get ? n.get.apply(t) : n.value) : e.stack
      });
    }), function (e) {
      xe.some(t => t._value === e._value) || xe.push(e);
    }(e), Re(e), n && Le();
  }
}
function Re(e) {
  var t = e._listeners;
  e._listeners = [];
  for (var n = 0, r = t.length; n < r; ++n) Fe(e, t[n]);
  var s = e._PSD;
  --s.ref || s.finalize(), 0 === Ae && (++Ae, be(() => {
    0 == --Ae && Ve();
  }, []));
}
function Fe(e, t) {
  if (null !== e._state) {
    var n = e._state ? t.onFulfilled : t.onRejected;
    if (null === n) return (e._state ? t.resolve : t.reject)(e._value);
    ++t.psd.ref, ++Ae, be(Me, [n, e, t]);
  } else e._listeners.push(t);
}
function Me(e, t, n) {
  try {
    Ee = t;
    var r,
      s = t._value;
    t._state ? r = e(s) : (ke.length && (ke = []), r = e(s), -1 === ke.indexOf(s) && function (e) {
      var t = xe.length;
      for (; t;) if (xe[--t]._value === e._value) return void xe.splice(t, 1);
    }(t)), n.resolve(r);
  } catch (e) {
    n.reject(e);
  } finally {
    Ee = null, 0 == --Ae && Ve(), --n.psd.ref || n.psd.finalize();
  }
}
function Ne(e, t, n) {
  if (t.length === n) return t;
  var r = "";
  if (!1 === e._state) {
    var s,
      i,
      o = e._value;
    null != o ? (s = o.name || "Error", i = o.message || o, r = $(o, 0)) : (s = o, i = ""), t.push(s + (i ? ": " + i : "") + r);
  }
  return R && ((r = $(e._stackHolder, 2)) && -1 === t.indexOf(r) && t.push(r), e._prev && Ne(e._prev, t, n)), t;
}
function qe(e, t) {
  var n = t ? t._numPrev + 1 : 0;
  n < 100 && (e._prev = t, e._numPrev = n);
}
function $e() {
  Ue() && Le();
}
function Ue() {
  var e = _e;
  return _e = !1, we = !1, e;
}
function Le() {
  var e, t, n;
  do {
    for (; Se.length > 0;) for (e = Se, Se = [], n = e.length, t = 0; t < n; ++t) {
      var r = e[t];
      r[0].apply(null, r[1]);
    }
  } while (Se.length > 0);
  _e = !0, we = !0;
}
function Ve() {
  var e = xe;
  xe = [], e.forEach(e => {
    e._PSD.onunhandled.call(null, e._value, e);
  });
  for (var t = Ce.slice(0), n = t.length; n;) t[--n]();
}
function We(e) {
  return new je(le, !1, e);
}
function Ye(e, t) {
  var n = Oe;
  return function () {
    var r = Ue(),
      s = Oe;
    try {
      return it(n, !0), e.apply(this, arguments);
    } catch (e) {
      t && t(e);
    } finally {
      it(s, !1), r && Le();
    }
  };
}
a(je.prototype, {
  then: De,
  _then: function (e, t) {
    Fe(this, new Ie(null, null, e, t, Oe));
  },
  catch: function (e) {
    if (1 === arguments.length) return this.then(null, e);
    var t = arguments[0],
      n = arguments[1];
    return "function" == typeof t ? this.then(null, e => e instanceof t ? n(e) : We(e)) : this.then(null, e => e && e.name === t ? n(e) : We(e));
  },
  finally: function (e) {
    return this.then(t => (e(), t), t => (e(), We(t)));
  },
  stack: {
    get: function () {
      if (this._stack) return this._stack;
      try {
        ve = !0;
        var e = Ne(this, [], 20).join("\nFrom previous: ");
        return null !== this._state && (this._stack = e), e;
      } finally {
        ve = !1;
      }
    }
  },
  timeout: function (e, t) {
    return e < 1 / 0 ? new je((n, r) => {
      var s = setTimeout(() => r(new X.Timeout(t)), e);
      this.then(n, r).finally(clearTimeout.bind(null, s));
    }) : this;
  }
}), "undefined" != typeof Symbol && Symbol.toStringTag && l(je.prototype, Symbol.toStringTag, "Dexie.Promise"), Ke.env = ot(), a(je, {
  all: function () {
    var e = B.apply(null, arguments).map(nt);
    return new je(function (t, n) {
      0 === e.length && t([]);
      var r = e.length;
      e.forEach((s, i) => je.resolve(s).then(n => {
        e[i] = n, --r || t(e);
      }, n));
    });
  },
  resolve: e => {
    if (e instanceof je) return e;
    if (e && "function" == typeof e.then) return new je((t, n) => {
      e.then(t, n);
    });
    var t = new je(le, !0, e);
    return qe(t, Ee), t;
  },
  reject: We,
  race: function () {
    var e = B.apply(null, arguments).map(nt);
    return new je((t, n) => {
      e.map(e => je.resolve(e).then(t, n));
    });
  },
  PSD: {
    get: () => Oe,
    set: e => Oe = e
  },
  totalEchoes: {
    get: () => Xe
  },
  newPSD: Ze,
  usePSD: at,
  scheduler: {
    get: () => be,
    set: e => {
      be = e;
    }
  },
  rejectionMapper: {
    get: () => Pe,
    set: e => {
      Pe = e;
    }
  },
  follow: (e, t) => new je((n, r) => Ze((t, n) => {
    var r = Oe;
    r.unhandleds = [], r.onunhandled = n, r.finalize = re(function () {
      !function (e) {
        function t() {
          e(), Ce.splice(Ce.indexOf(t), 1);
        }
        Ce.push(t), ++Ae, be(() => {
          0 == --Ae && Ve();
        }, []);
      }(() => {
        0 === this.unhandleds.length ? t() : n(this.unhandleds[0]);
      });
    }, r.finalize), e();
  }, t, n, r))
}), ye && (ye.allSettled && l(je, "allSettled", function () {
  const e = B.apply(null, arguments).map(nt);
  return new je(t => {
    0 === e.length && t([]);
    let n = e.length;
    const r = new Array(n);
    e.forEach((e, s) => je.resolve(e).then(e => r[s] = {
      status: "fulfilled",
      value: e
    }, e => r[s] = {
      status: "rejected",
      reason: e
    }).then(() => --n || t(r)));
  });
}), ye.any && "undefined" != typeof AggregateError && l(je, "any", function () {
  const e = B.apply(null, arguments).map(nt);
  return new je((t, n) => {
    0 === e.length && n(new AggregateError([]));
    let r = e.length;
    const s = new Array(r);
    e.forEach((e, i) => je.resolve(e).then(e => t(e), e => {
      s[i] = e, --r || n(new AggregateError(s));
    }));
  });
}));
const ze = {
  awaits: 0,
  echoes: 0,
  id: 0
};
var Ge = 0,
  He = [],
  Qe = 0,
  Xe = 0,
  Je = 0;
function Ze(e, t, n, s) {
  var i = Oe,
    o = Object.create(i);
  o.parent = i, o.ref = 0, o.global = !1, o.id = ++Je;
  var a = Ke.env;
  o.env = me ? {
    Promise: je,
    PromiseProp: {
      value: je,
      configurable: !0,
      writable: !0
    },
    all: je.all,
    race: je.race,
    allSettled: je.allSettled,
    any: je.any,
    resolve: je.resolve,
    reject: je.reject,
    nthen: ct(a.nthen, o),
    gthen: ct(a.gthen, o)
  } : {}, t && r(o, t), ++i.ref, o.finalize = function () {
    --this.parent.ref || this.parent.finalize();
  };
  var u = at(o, e, n, s);
  return 0 === o.ref && o.finalize(), u;
}
function et() {
  return ze.id || (ze.id = ++Ge), ++ze.awaits, ze.echoes += ce, ze.id;
}
function tt() {
  return !!ze.awaits && (0 == --ze.awaits && (ze.id = 0), ze.echoes = ze.awaits * ce, !0);
}
function nt(e) {
  return ze.echoes && e && e.constructor === ye ? (et(), e.then(e => (tt(), e), e => (tt(), ft(e)))) : e;
}
function rt(e) {
  ++Xe, ze.echoes && 0 != --ze.echoes || (ze.echoes = ze.id = 0), He.push(Oe), it(e, !0);
}
function st() {
  var e = He[He.length - 1];
  He.pop(), it(e, !1);
}
function it(t, n) {
  var r = Oe;
  if ((n ? !ze.echoes || Qe++ && t === Oe : !Qe || --Qe && t === Oe) || ut(n ? rt.bind(null, t) : st), t !== Oe && (Oe = t, r === Ke && (Ke.env = ot()), me)) {
    var s = Ke.env.Promise,
      i = t.env;
    de.then = i.nthen, s.prototype.then = i.gthen, (r.global || t.global) && (Object.defineProperty(e, "Promise", i.PromiseProp), s.all = i.all, s.race = i.race, s.resolve = i.resolve, s.reject = i.reject, i.allSettled && (s.allSettled = i.allSettled), i.any && (s.any = i.any));
  }
}
function ot() {
  var t = e.Promise;
  return me ? {
    Promise: t,
    PromiseProp: Object.getOwnPropertyDescriptor(e, "Promise"),
    all: t.all,
    race: t.race,
    allSettled: t.allSettled,
    any: t.any,
    resolve: t.resolve,
    reject: t.reject,
    nthen: de.then,
    gthen: t.prototype.then
  } : {};
}
function at(e, t, n, r, s) {
  var i = Oe;
  try {
    return it(e, !0), t(n, r, s);
  } finally {
    it(i, !1);
  }
}
function ut(e) {
  pe.call(he, e);
}
function lt(e, t, n, r) {
  return "function" != typeof e ? e : function () {
    var s = Oe;
    n && et(), it(t, !0);
    try {
      return e.apply(this, arguments);
    } finally {
      it(s, !1), r && ut(tt);
    }
  };
}
function ct(e, t) {
  return function (n, r) {
    return e.call(this, lt(n, t), lt(r, t));
  };
}
-1 === ("" + pe).indexOf("[native code]") && (et = tt = ee);
const ht = "unhandledrejection";
function dt(t, n) {
  var s;
  try {
    s = n.onuncatched(t);
  } catch (e) {}
  if (!1 !== s) try {
    var i,
      o = {
        promise: n,
        reason: t
      };
    if (e.document && document.createEvent ? ((i = document.createEvent("Event")).initEvent(ht, !0, !0), r(i, o)) : e.CustomEvent && r(i = new CustomEvent(ht, {
      detail: o
    }), o), i && e.dispatchEvent && (dispatchEvent(i), !e.PromiseRejectionEvent && e.onunhandledrejection)) try {
      e.onunhandledrejection(i);
    } catch (e) {}
    R && i && !i.defaultPrevented && console.warn(`Unhandled rejection: ${t.stack || t}`);
  } catch (e) {}
}
var ft = je.reject;
function pt(e, t, n, r) {
  if (e.idbdb && (e._state.openComplete || Oe.letThrough || e._vip)) {
    var s = e._createTransaction(t, n, e._dbSchema);
    try {
      s.create(), e._state.PR1398_maxLoop = 3;
    } catch (s) {
      return s.name === H.InvalidState && e.isOpen() && --e._state.PR1398_maxLoop > 0 ? (console.warn("Dexie: Need to reopen db"), e._close(), e.open().then(() => pt(e, t, n, r))) : ft(s);
    }
    return s._promise(t, (e, t) => Ze(() => (Oe.trans = s, r(e, t, s)))).then(e => s._completion.then(() => e));
  }
  if (e._state.openComplete) return ft(new X.DatabaseClosed(e._state.dbOpenError));
  if (!e._state.isBeingOpened) {
    if (!e._options.autoOpen) return ft(new X.DatabaseClosed());
    e.open().catch(ee);
  }
  return e._state.dbReadyPromise.then(() => pt(e, t, n, r));
}
const yt = "3.2.7",
  mt = String.fromCharCode(65535),
  vt = -1 / 0,
  gt = "Invalid key provided. Keys must be of type string, number, Date or Array<string | number | Date>.",
  bt = "String expected.",
  _t = [],
  wt = "undefined" != typeof navigator && /(MSIE|Trident|Edge)/.test(navigator.userAgent),
  xt = wt,
  kt = wt,
  Et = e => !/(dexie\.js|dexie\.min\.js)/.test(e),
  Pt = "__dbnames",
  Kt = "readonly",
  Ot = "readwrite";
function St(e, t) {
  return e ? t ? function () {
    return e.apply(this, arguments) && t.apply(this, arguments);
  } : e : t;
}
const At = {
  type: 3,
  lower: -1 / 0,
  lowerOpen: !1,
  upper: [[]],
  upperOpen: !1
};
function Ct(e) {
  return "string" != typeof e || /\./.test(e) ? e => e : t => (void 0 === t[e] && e in t && delete (t = O(t))[e], t);
}
class jt {
  _trans(e, t, n) {
    const r = this._tx || Oe.trans,
      s = this.name;
    function i(e, n, r) {
      if (!r.schema[s]) throw new X.NotFound("Table " + s + " not part of transaction");
      return t(r.idbtrans, r);
    }
    const o = Ue();
    try {
      return r && r.db === this.db ? r === Oe.trans ? r._promise(e, i, n) : Ze(() => r._promise(e, i, n), {
        trans: r,
        transless: Oe.transless || Oe
      }) : pt(this.db, e, [this.name], i);
    } finally {
      o && Le();
    }
  }
  get(e, t) {
    return e && e.constructor === Object ? this.where(e).first(t) : this._trans("readonly", t => this.core.get({
      trans: t,
      key: e
    }).then(e => this.hook.reading.fire(e))).then(t);
  }
  where(e) {
    if ("string" == typeof e) return new this.db.WhereClause(this, e);
    if (n(e)) return new this.db.WhereClause(this, `[${e.join("+")}]`);
    const r = t(e);
    if (1 === r.length) return this.where(r[0]).equals(e[r[0]]);
    const s = this.schema.indexes.concat(this.schema.primKey).filter(e => {
      if (e.compound && r.every(t => e.keyPath.indexOf(t) >= 0)) {
        for (let t = 0; t < r.length; ++t) if (-1 === r.indexOf(e.keyPath[t])) return !1;
        return !0;
      }
      return !1;
    }).sort((e, t) => e.keyPath.length - t.keyPath.length)[0];
    if (s && this.db._maxKey !== mt) {
      const t = s.keyPath.slice(0, r.length);
      return this.where(t).equals(t.map(t => e[t]));
    }
    !s && R && console.warn(`The query ${JSON.stringify(e)} on ${this.name} would benefit of a compound index [${r.join("+")}]`);
    const {
        idxByName: i
      } = this.schema,
      o = this.db._deps.indexedDB;
    function a(e, t) {
      try {
        return 0 === o.cmp(e, t);
      } catch (e) {
        return !1;
      }
    }
    const [u, l] = r.reduce(([t, r], s) => {
      const o = i[s],
        u = e[s];
      return [t || o, t || !o ? St(r, o && o.multi ? e => {
        const t = b(e, s);
        return n(t) && t.some(e => a(u, e));
      } : e => a(u, b(e, s))) : r];
    }, [null, null]);
    return u ? this.where(u.name).equals(e[u.keyPath]).filter(l) : s ? this.filter(l) : this.where(r).equals("");
  }
  filter(e) {
    return this.toCollection().and(e);
  }
  count(e) {
    return this.toCollection().count(e);
  }
  offset(e) {
    return this.toCollection().offset(e);
  }
  limit(e) {
    return this.toCollection().limit(e);
  }
  each(e) {
    return this.toCollection().each(e);
  }
  toArray(e) {
    return this.toCollection().toArray(e);
  }
  toCollection() {
    return new this.db.Collection(new this.db.WhereClause(this));
  }
  orderBy(e) {
    return new this.db.Collection(new this.db.WhereClause(this, n(e) ? `[${e.join("+")}]` : e));
  }
  reverse() {
    return this.toCollection().reverse();
  }
  mapToClass(e) {
    this.schema.mappedClass = e;
    const t = t => {
      if (!t) return t;
      const n = Object.create(e.prototype);
      for (var r in t) if (o(t, r)) try {
        n[r] = t[r];
      } catch (e) {}
      return n;
    };
    return this.schema.readHook && this.hook.reading.unsubscribe(this.schema.readHook), this.schema.readHook = t, this.hook("reading", t), e;
  }
  defineClass() {
    return this.mapToClass(function (e) {
      r(this, e);
    });
  }
  add(e, t) {
    const {
      auto: n,
      keyPath: r
    } = this.schema.primKey;
    let s = e;
    return r && n && (s = Ct(r)(e)), this._trans("readwrite", e => this.core.mutate({
      trans: e,
      type: "add",
      keys: null != t ? [t] : null,
      values: [s]
    })).then(e => e.numFailures ? je.reject(e.failures[0]) : e.lastResult).then(t => {
      if (r) try {
        _(e, r, t);
      } catch (e) {}
      return t;
    });
  }
  update(e, r) {
    if ("object" != typeof e || n(e)) return this.where(":id").equals(e).modify(r);
    {
      const n = b(e, this.schema.primKey.keyPath);
      if (void 0 === n) return ft(new X.InvalidArgument("Given object does not contain its primary key"));
      try {
        "function" != typeof r ? t(r).forEach(t => {
          _(e, t, r[t]);
        }) : r(e, {
          value: e,
          primKey: n
        });
      } catch (e) {}
      return this.where(":id").equals(n).modify(r);
    }
  }
  put(e, t) {
    const {
      auto: n,
      keyPath: r
    } = this.schema.primKey;
    let s = e;
    return r && n && (s = Ct(r)(e)), this._trans("readwrite", e => this.core.mutate({
      trans: e,
      type: "put",
      values: [s],
      keys: null != t ? [t] : null
    })).then(e => e.numFailures ? je.reject(e.failures[0]) : e.lastResult).then(t => {
      if (r) try {
        _(e, r, t);
      } catch (e) {}
      return t;
    });
  }
  delete(e) {
    return this._trans("readwrite", t => this.core.mutate({
      trans: t,
      type: "delete",
      keys: [e]
    })).then(e => e.numFailures ? je.reject(e.failures[0]) : void 0);
  }
  clear() {
    return this._trans("readwrite", e => this.core.mutate({
      trans: e,
      type: "deleteRange",
      range: At
    })).then(e => e.numFailures ? je.reject(e.failures[0]) : void 0);
  }
  bulkGet(e) {
    return this._trans("readonly", t => this.core.getMany({
      keys: e,
      trans: t
    }).then(e => e.map(e => this.hook.reading.fire(e))));
  }
  bulkAdd(e, t, n) {
    const r = Array.isArray(t) ? t : void 0,
      s = (n = n || (r ? void 0 : t)) ? n.allKeys : void 0;
    return this._trans("readwrite", t => {
      const {
        auto: n,
        keyPath: i
      } = this.schema.primKey;
      if (i && r) throw new X.InvalidArgument("bulkAdd(): keys argument invalid on tables with inbound keys");
      if (r && r.length !== e.length) throw new X.InvalidArgument("Arguments objects and keys must have the same length");
      const o = e.length;
      let a = i && n ? e.map(Ct(i)) : e;
      return this.core.mutate({
        trans: t,
        type: "add",
        keys: r,
        values: a,
        wantResults: s
      }).then(({
        numFailures: e,
        results: t,
        lastResult: n,
        failures: r
      }) => {
        if (0 === e) return s ? t : n;
        throw new G(`${this.name}.bulkAdd(): ${e} of ${o} operations failed`, r);
      });
    });
  }
  bulkPut(e, t, n) {
    const r = Array.isArray(t) ? t : void 0,
      s = (n = n || (r ? void 0 : t)) ? n.allKeys : void 0;
    return this._trans("readwrite", t => {
      const {
        auto: n,
        keyPath: i
      } = this.schema.primKey;
      if (i && r) throw new X.InvalidArgument("bulkPut(): keys argument invalid on tables with inbound keys");
      if (r && r.length !== e.length) throw new X.InvalidArgument("Arguments objects and keys must have the same length");
      const o = e.length;
      let a = i && n ? e.map(Ct(i)) : e;
      return this.core.mutate({
        trans: t,
        type: "put",
        keys: r,
        values: a,
        wantResults: s
      }).then(({
        numFailures: e,
        results: t,
        lastResult: n,
        failures: r
      }) => {
        if (0 === e) return s ? t : n;
        throw new G(`${this.name}.bulkPut(): ${e} of ${o} operations failed`, r);
      });
    });
  }
  bulkDelete(e) {
    const t = e.length;
    return this._trans("readwrite", t => this.core.mutate({
      trans: t,
      type: "delete",
      keys: e
    })).then(({
      numFailures: e,
      lastResult: n,
      failures: r
    }) => {
      if (0 === e) return n;
      throw new G(`${this.name}.bulkDelete(): ${e} of ${t} operations failed`, r);
    });
  }
}
function Dt(e) {
  var r = {},
    s = function (t, n) {
      if (n) {
        for (var s = arguments.length, i = new Array(s - 1); --s;) i[s - 1] = arguments[s];
        return r[t].subscribe.apply(null, i), e;
      }
      if ("string" == typeof t) return r[t];
    };
  s.addEventType = a;
  for (var i = 1, o = arguments.length; i < o; ++i) a(arguments[i]);
  return s;
  function a(e, i, o) {
    if ("object" != typeof e) {
      var u;
      i || (i = ae), o || (o = ee);
      var l = {
        subscribers: [],
        fire: o,
        subscribe: function (e) {
          -1 === l.subscribers.indexOf(e) && (l.subscribers.push(e), l.fire = i(l.fire, e));
        },
        unsubscribe: function (e) {
          l.subscribers = l.subscribers.filter(function (t) {
            return t !== e;
          }), l.fire = l.subscribers.reduce(i, o);
        }
      };
      return r[e] = s[e] = l, l;
    }
    t(u = e).forEach(function (e) {
      var t = u[e];
      if (n(t)) a(e, u[e][0], u[e][1]);else {
        if ("asap" !== t) throw new X.InvalidArgument("Invalid event config");
        var r = a(e, te, function () {
          for (var e = arguments.length, t = new Array(e); e--;) t[e] = arguments[e];
          r.subscribers.forEach(function (e) {
            v(function () {
              e.apply(null, t);
            });
          });
        });
      }
    });
  }
}
function It(e, t) {
  return c(t).from({
    prototype: e
  }), t;
}
function Bt(e, t) {
  return !(e.filter || e.algorithm || e.or) && (t ? e.justLimit : !e.replayFilter);
}
function Tt(e, t) {
  e.filter = St(e.filter, t);
}
function Rt(e, t, n) {
  var r = e.replayFilter;
  e.replayFilter = r ? () => St(r(), t()) : t, e.justLimit = n && !r;
}
function Ft(e, t) {
  if (e.isPrimKey) return t.primaryKey;
  const n = t.getIndexByKeyPath(e.index);
  if (!n) throw new X.Schema("KeyPath " + e.index + " on object store " + t.name + " is not indexed");
  return n;
}
function Mt(e, t, n) {
  const r = Ft(e, t.schema);
  return t.openCursor({
    trans: n,
    values: !e.keysOnly,
    reverse: "prev" === e.dir,
    unique: !!e.unique,
    query: {
      index: r,
      range: e.range
    }
  });
}
function Nt(e, t, n, r) {
  const s = e.replayFilter ? St(e.filter, e.replayFilter()) : e.filter;
  if (e.or) {
    const i = {},
      a = (e, n, r) => {
        if (!s || s(n, r, e => n.stop(e), e => n.fail(e))) {
          var a = n.primaryKey,
            u = "" + a;
          "[object ArrayBuffer]" === u && (u = "" + new Uint8Array(a)), o(i, u) || (i[u] = !0, t(e, n, r));
        }
      };
    return Promise.all([e.or._iterate(a, n), qt(Mt(e, r, n), e.algorithm, a, !e.keysOnly && e.valueMapper)]);
  }
  return qt(Mt(e, r, n), St(e.algorithm, s), t, !e.keysOnly && e.valueMapper);
}
function qt(e, t, n, r) {
  var s = Ye(r ? (e, t, s) => n(r(e), t, s) : n);
  return e.then(e => {
    if (e) return e.start(() => {
      var n = () => e.continue();
      t && !t(e, e => n = e, t => {
        e.stop(t), n = ee;
      }, t => {
        e.fail(t), n = ee;
      }) || s(e.value, e, e => n = e), n();
    });
  });
}
function $t(e, t) {
  try {
    const n = Ut(e),
      r = Ut(t);
    if (n !== r) return "Array" === n ? 1 : "Array" === r ? -1 : "binary" === n ? 1 : "binary" === r ? -1 : "string" === n ? 1 : "string" === r ? -1 : "Date" === n ? 1 : "Date" !== r ? NaN : -1;
    switch (n) {
      case "number":
      case "Date":
      case "string":
        return e > t ? 1 : e < t ? -1 : 0;
      case "binary":
        return function (e, t) {
          const n = e.length,
            r = t.length,
            s = n < r ? n : r;
          for (let n = 0; n < s; ++n) if (e[n] !== t[n]) return e[n] < t[n] ? -1 : 1;
          return n === r ? 0 : n < r ? -1 : 1;
        }(Lt(e), Lt(t));
      case "Array":
        return function (e, t) {
          const n = e.length,
            r = t.length,
            s = n < r ? n : r;
          for (let n = 0; n < s; ++n) {
            const r = $t(e[n], t[n]);
            if (0 !== r) return r;
          }
          return n === r ? 0 : n < r ? -1 : 1;
        }(e, t);
    }
  } catch (e) {}
  return NaN;
}
function Ut(e) {
  const t = typeof e;
  if ("object" !== t) return t;
  if (ArrayBuffer.isView(e)) return "binary";
  const n = C(e);
  return "ArrayBuffer" === n ? "binary" : n;
}
function Lt(e) {
  return e instanceof Uint8Array ? e : ArrayBuffer.isView(e) ? new Uint8Array(e.buffer, e.byteOffset, e.byteLength) : new Uint8Array(e);
}
class Vt {
  _read(e, t) {
    var n = this._ctx;
    return n.error ? n.table._trans(null, ft.bind(null, n.error)) : n.table._trans("readonly", e).then(t);
  }
  _write(e) {
    var t = this._ctx;
    return t.error ? t.table._trans(null, ft.bind(null, t.error)) : t.table._trans("readwrite", e, "locked");
  }
  _addAlgorithm(e) {
    var t = this._ctx;
    t.algorithm = St(t.algorithm, e);
  }
  _iterate(e, t) {
    return Nt(this._ctx, e, t, this._ctx.table.core);
  }
  clone(e) {
    var t = Object.create(this.constructor.prototype),
      n = Object.create(this._ctx);
    return e && r(n, e), t._ctx = n, t;
  }
  raw() {
    return this._ctx.valueMapper = null, this;
  }
  each(e) {
    var t = this._ctx;
    return this._read(n => Nt(t, e, n, t.table.core));
  }
  count(e) {
    return this._read(e => {
      const t = this._ctx,
        n = t.table.core;
      if (Bt(t, !0)) return n.count({
        trans: e,
        query: {
          index: Ft(t, n.schema),
          range: t.range
        }
      }).then(e => Math.min(e, t.limit));
      var r = 0;
      return Nt(t, () => (++r, !1), e, n).then(() => r);
    }).then(e);
  }
  sortBy(e, t) {
    const n = e.split(".").reverse(),
      r = n[0],
      s = n.length - 1;
    function i(e, t) {
      return t ? i(e[n[t]], t - 1) : e[r];
    }
    var o = "next" === this._ctx.dir ? 1 : -1;
    function a(e, t) {
      var n = i(e, s),
        r = i(t, s);
      return n < r ? -o : n > r ? o : 0;
    }
    return this.toArray(function (e) {
      return e.sort(a);
    }).then(t);
  }
  toArray(e) {
    return this._read(e => {
      var t = this._ctx;
      if ("next" === t.dir && Bt(t, !0) && t.limit > 0) {
        const {
            valueMapper: n
          } = t,
          r = Ft(t, t.table.core.schema);
        return t.table.core.query({
          trans: e,
          limit: t.limit,
          values: !0,
          query: {
            index: r,
            range: t.range
          }
        }).then(({
          result: e
        }) => n ? e.map(n) : e);
      }
      {
        const n = [];
        return Nt(t, e => n.push(e), e, t.table.core).then(() => n);
      }
    }, e);
  }
  offset(e) {
    var t = this._ctx;
    return e <= 0 || (t.offset += e, Bt(t) ? Rt(t, () => {
      var t = e;
      return (e, n) => 0 === t || (1 === t ? (--t, !1) : (n(() => {
        e.advance(t), t = 0;
      }), !1));
    }) : Rt(t, () => {
      var t = e;
      return () => --t < 0;
    })), this;
  }
  limit(e) {
    return this._ctx.limit = Math.min(this._ctx.limit, e), Rt(this._ctx, () => {
      var t = e;
      return function (e, n, r) {
        return --t <= 0 && n(r), t >= 0;
      };
    }, !0), this;
  }
  until(e, t) {
    return Tt(this._ctx, function (n, r, s) {
      return !e(n.value) || (r(s), t);
    }), this;
  }
  first(e) {
    return this.limit(1).toArray(function (e) {
      return e[0];
    }).then(e);
  }
  last(e) {
    return this.reverse().first(e);
  }
  filter(e) {
    var t, n;
    return Tt(this._ctx, function (t) {
      return e(t.value);
    }), t = this._ctx, n = e, t.isMatch = St(t.isMatch, n), this;
  }
  and(e) {
    return this.filter(e);
  }
  or(e) {
    return new this.db.WhereClause(this._ctx.table, e, this);
  }
  reverse() {
    return this._ctx.dir = "prev" === this._ctx.dir ? "next" : "prev", this._ondirectionchange && this._ondirectionchange(this._ctx.dir), this;
  }
  desc() {
    return this.reverse();
  }
  eachKey(e) {
    var t = this._ctx;
    return t.keysOnly = !t.isMatch, this.each(function (t, n) {
      e(n.key, n);
    });
  }
  eachUniqueKey(e) {
    return this._ctx.unique = "unique", this.eachKey(e);
  }
  eachPrimaryKey(e) {
    var t = this._ctx;
    return t.keysOnly = !t.isMatch, this.each(function (t, n) {
      e(n.primaryKey, n);
    });
  }
  keys(e) {
    var t = this._ctx;
    t.keysOnly = !t.isMatch;
    var n = [];
    return this.each(function (e, t) {
      n.push(t.key);
    }).then(function () {
      return n;
    }).then(e);
  }
  primaryKeys(e) {
    var t = this._ctx;
    if ("next" === t.dir && Bt(t, !0) && t.limit > 0) return this._read(e => {
      var n = Ft(t, t.table.core.schema);
      return t.table.core.query({
        trans: e,
        values: !1,
        limit: t.limit,
        query: {
          index: n,
          range: t.range
        }
      });
    }).then(({
      result: e
    }) => e).then(e);
    t.keysOnly = !t.isMatch;
    var n = [];
    return this.each(function (e, t) {
      n.push(t.primaryKey);
    }).then(function () {
      return n;
    }).then(e);
  }
  uniqueKeys(e) {
    return this._ctx.unique = "unique", this.keys(e);
  }
  firstKey(e) {
    return this.limit(1).keys(function (e) {
      return e[0];
    }).then(e);
  }
  lastKey(e) {
    return this.reverse().firstKey(e);
  }
  distinct() {
    var e = this._ctx,
      t = e.index && e.table.schema.idxByName[e.index];
    if (!t || !t.multi) return this;
    var n = {};
    return Tt(this._ctx, function (e) {
      var t = e.primaryKey.toString(),
        r = o(n, t);
      return n[t] = !0, !r;
    }), this;
  }
  modify(e) {
    var n = this._ctx;
    return this._write(r => {
      var s;
      if ("function" == typeof e) s = e;else {
        var i = t(e),
          o = i.length;
        s = function (t) {
          for (var n = !1, r = 0; r < o; ++r) {
            var s = i[r],
              a = e[s];
            b(t, s) !== a && (_(t, s, a), n = !0);
          }
          return n;
        };
      }
      const a = n.table.core,
        {
          outbound: u,
          extractKey: l
        } = a.schema.primaryKey,
        c = this.db._options.modifyChunkSize || 200,
        h = [];
      let d = 0;
      const f = [],
        p = (e, n) => {
          const {
            failures: r,
            numFailures: s
          } = n;
          d += e - s;
          for (let e of t(r)) h.push(r[e]);
        };
      return this.clone().primaryKeys().then(t => {
        const i = o => {
          const h = Math.min(c, t.length - o);
          return a.getMany({
            trans: r,
            keys: t.slice(o, o + h),
            cache: "immutable"
          }).then(d => {
            const f = [],
              y = [],
              m = u ? [] : null,
              v = [];
            for (let e = 0; e < h; ++e) {
              const n = d[e],
                r = {
                  value: O(n),
                  primKey: t[o + e]
                };
              !1 !== s.call(r, r.value, r) && (null == r.value ? v.push(t[o + e]) : u || 0 === $t(l(n), l(r.value)) ? (y.push(r.value), u && m.push(t[o + e])) : (v.push(t[o + e]), f.push(r.value)));
            }
            const g = Bt(n) && n.limit === 1 / 0 && ("function" != typeof e || e === Wt) && {
              index: n.index,
              range: n.range
            };
            return Promise.resolve(f.length > 0 && a.mutate({
              trans: r,
              type: "add",
              values: f
            }).then(e => {
              for (let t in e.failures) v.splice(parseInt(t), 1);
              p(f.length, e);
            })).then(() => (y.length > 0 || g && "object" == typeof e) && a.mutate({
              trans: r,
              type: "put",
              keys: m,
              values: y,
              criteria: g,
              changeSpec: "function" != typeof e && e
            }).then(e => p(y.length, e))).then(() => (v.length > 0 || g && e === Wt) && a.mutate({
              trans: r,
              type: "delete",
              keys: v,
              criteria: g
            }).then(e => p(v.length, e))).then(() => t.length > o + h && i(o + c));
          });
        };
        return i(0).then(() => {
          if (h.length > 0) throw new z("Error modifying one or more objects", h, d, f);
          return t.length;
        });
      });
    });
  }
  delete() {
    var e = this._ctx,
      t = e.range;
    return Bt(e) && (e.isPrimKey && !kt || 3 === t.type) ? this._write(n => {
      const {
          primaryKey: r
        } = e.table.core.schema,
        s = t;
      return e.table.core.count({
        trans: n,
        query: {
          index: r,
          range: s
        }
      }).then(t => e.table.core.mutate({
        trans: n,
        type: "deleteRange",
        range: s
      }).then(({
        failures: e,
        lastResult: n,
        results: r,
        numFailures: s
      }) => {
        if (s) throw new z("Could not delete some values", Object.keys(e).map(t => e[t]), t - s);
        return t - s;
      }));
    }) : this.modify(Wt);
  }
}
const Wt = (e, t) => t.value = null;
function Yt(e, t) {
  return e < t ? -1 : e === t ? 0 : 1;
}
function zt(e, t) {
  return e > t ? -1 : e === t ? 0 : 1;
}
function Gt(e, t, n) {
  var r = e instanceof en ? new e.Collection(e) : e;
  return r._ctx.error = n ? new n(t) : new TypeError(t), r;
}
function Ht(e) {
  return new e.Collection(e, () => Zt("")).limit(0);
}
function Qt(e, t, n, r, s, i) {
  for (var o = Math.min(e.length, r.length), a = -1, u = 0; u < o; ++u) {
    var l = t[u];
    if (l !== r[u]) return s(e[u], n[u]) < 0 ? e.substr(0, u) + n[u] + n.substr(u + 1) : s(e[u], r[u]) < 0 ? e.substr(0, u) + r[u] + n.substr(u + 1) : a >= 0 ? e.substr(0, a) + t[a] + n.substr(a + 1) : null;
    s(e[u], l) < 0 && (a = u);
  }
  return o < r.length && "next" === i ? e + n.substr(e.length) : o < e.length && "prev" === i ? e.substr(0, n.length) : a < 0 ? null : e.substr(0, a) + r[a] + n.substr(a + 1);
}
function Xt(e, t, n, r) {
  var s,
    i,
    o,
    a,
    u,
    l,
    c,
    h = n.length;
  if (!n.every(e => "string" == typeof e)) return Gt(e, bt);
  function d(e) {
    s = function (e) {
      return "next" === e ? e => e.toUpperCase() : e => e.toLowerCase();
    }(e), i = function (e) {
      return "next" === e ? e => e.toLowerCase() : e => e.toUpperCase();
    }(e), o = "next" === e ? Yt : zt;
    var t = n.map(function (e) {
      return {
        lower: i(e),
        upper: s(e)
      };
    }).sort(function (e, t) {
      return o(e.lower, t.lower);
    });
    a = t.map(function (e) {
      return e.upper;
    }), u = t.map(function (e) {
      return e.lower;
    }), l = e, c = "next" === e ? "" : r;
  }
  d("next");
  var f = new e.Collection(e, () => Jt(a[0], u[h - 1] + r));
  f._ondirectionchange = function (e) {
    d(e);
  };
  var p = 0;
  return f._addAlgorithm(function (e, n, r) {
    var s = e.key;
    if ("string" != typeof s) return !1;
    var d = i(s);
    if (t(d, u, p)) return !0;
    for (var f = null, y = p; y < h; ++y) {
      var m = Qt(s, d, a[y], u[y], o, l);
      null === m && null === f ? p = y + 1 : (null === f || o(f, m) > 0) && (f = m);
    }
    return n(null !== f ? function () {
      e.continue(f + c);
    } : r), !1;
  }), f;
}
function Jt(e, t, n, r) {
  return {
    type: 2,
    lower: e,
    upper: t,
    lowerOpen: n,
    upperOpen: r
  };
}
function Zt(e) {
  return {
    type: 1,
    lower: e,
    upper: e
  };
}
class en {
  get Collection() {
    return this._ctx.table.db.Collection;
  }
  between(e, t, n, r) {
    n = !1 !== n, r = !0 === r;
    try {
      return this._cmp(e, t) > 0 || 0 === this._cmp(e, t) && (n || r) && (!n || !r) ? Ht(this) : new this.Collection(this, () => Jt(e, t, !n, !r));
    } catch (e) {
      return Gt(this, gt);
    }
  }
  equals(e) {
    return null == e ? Gt(this, gt) : new this.Collection(this, () => Zt(e));
  }
  above(e) {
    return null == e ? Gt(this, gt) : new this.Collection(this, () => Jt(e, void 0, !0));
  }
  aboveOrEqual(e) {
    return null == e ? Gt(this, gt) : new this.Collection(this, () => Jt(e, void 0, !1));
  }
  below(e) {
    return null == e ? Gt(this, gt) : new this.Collection(this, () => Jt(void 0, e, !1, !0));
  }
  belowOrEqual(e) {
    return null == e ? Gt(this, gt) : new this.Collection(this, () => Jt(void 0, e));
  }
  startsWith(e) {
    return "string" != typeof e ? Gt(this, bt) : this.between(e, e + mt, !0, !0);
  }
  startsWithIgnoreCase(e) {
    return "" === e ? this.startsWith(e) : Xt(this, (e, t) => 0 === e.indexOf(t[0]), [e], mt);
  }
  equalsIgnoreCase(e) {
    return Xt(this, (e, t) => e === t[0], [e], "");
  }
  anyOfIgnoreCase() {
    var e = B.apply(I, arguments);
    return 0 === e.length ? Ht(this) : Xt(this, (e, t) => -1 !== t.indexOf(e), e, "");
  }
  startsWithAnyOfIgnoreCase() {
    var e = B.apply(I, arguments);
    return 0 === e.length ? Ht(this) : Xt(this, (e, t) => t.some(t => 0 === e.indexOf(t)), e, mt);
  }
  anyOf() {
    const e = B.apply(I, arguments);
    let t = this._cmp;
    try {
      e.sort(t);
    } catch (e) {
      return Gt(this, gt);
    }
    if (0 === e.length) return Ht(this);
    const n = new this.Collection(this, () => Jt(e[0], e[e.length - 1]));
    n._ondirectionchange = n => {
      t = "next" === n ? this._ascending : this._descending, e.sort(t);
    };
    let r = 0;
    return n._addAlgorithm((n, s, i) => {
      const o = n.key;
      for (; t(o, e[r]) > 0;) if (++r, r === e.length) return s(i), !1;
      return 0 === t(o, e[r]) || (s(() => {
        n.continue(e[r]);
      }), !1);
    }), n;
  }
  notEqual(e) {
    return this.inAnyRange([[vt, e], [e, this.db._maxKey]], {
      includeLowers: !1,
      includeUppers: !1
    });
  }
  noneOf() {
    const e = B.apply(I, arguments);
    if (0 === e.length) return new this.Collection(this);
    try {
      e.sort(this._ascending);
    } catch (e) {
      return Gt(this, gt);
    }
    const t = e.reduce((e, t) => e ? e.concat([[e[e.length - 1][1], t]]) : [[vt, t]], null);
    return t.push([e[e.length - 1], this.db._maxKey]), this.inAnyRange(t, {
      includeLowers: !1,
      includeUppers: !1
    });
  }
  inAnyRange(e, t) {
    const n = this._cmp,
      r = this._ascending,
      s = this._descending,
      i = this._min,
      o = this._max;
    if (0 === e.length) return Ht(this);
    if (!e.every(e => void 0 !== e[0] && void 0 !== e[1] && r(e[0], e[1]) <= 0)) return Gt(this, "First argument to inAnyRange() must be an Array of two-value Arrays [lower,upper] where upper must not be lower than lower", X.InvalidArgument);
    const a = !t || !1 !== t.includeLowers,
      u = t && !0 === t.includeUppers;
    let l,
      c = r;
    function h(e, t) {
      return c(e[0], t[0]);
    }
    try {
      l = e.reduce(function (e, t) {
        let r = 0,
          s = e.length;
        for (; r < s; ++r) {
          const s = e[r];
          if (n(t[0], s[1]) < 0 && n(t[1], s[0]) > 0) {
            s[0] = i(s[0], t[0]), s[1] = o(s[1], t[1]);
            break;
          }
        }
        return r === s && e.push(t), e;
      }, []), l.sort(h);
    } catch (e) {
      return Gt(this, gt);
    }
    let d = 0;
    const f = u ? e => r(e, l[d][1]) > 0 : e => r(e, l[d][1]) >= 0,
      p = a ? e => s(e, l[d][0]) > 0 : e => s(e, l[d][0]) >= 0;
    let y = f;
    const m = new this.Collection(this, () => Jt(l[0][0], l[l.length - 1][1], !a, !u));
    return m._ondirectionchange = e => {
      "next" === e ? (y = f, c = r) : (y = p, c = s), l.sort(h);
    }, m._addAlgorithm((e, t, n) => {
      for (var s = e.key; y(s);) if (++d, d === l.length) return t(n), !1;
      return !!function (e) {
        return !f(e) && !p(e);
      }(s) || (0 === this._cmp(s, l[d][1]) || 0 === this._cmp(s, l[d][0]) || t(() => {
        c === r ? e.continue(l[d][0]) : e.continue(l[d][1]);
      }), !1);
    }), m;
  }
  startsWithAnyOf() {
    const e = B.apply(I, arguments);
    return e.every(e => "string" == typeof e) ? 0 === e.length ? Ht(this) : this.inAnyRange(e.map(e => [e, e + mt])) : Gt(this, "startsWithAnyOf() only works with strings");
  }
}
function tn(e) {
  return Ye(function (t) {
    return nn(t), e(t.target.error), !1;
  });
}
function nn(e) {
  e.stopPropagation && e.stopPropagation(), e.preventDefault && e.preventDefault();
}
const rn = "storagemutated",
  sn = "x-storagemutated-1",
  on = Dt(null, rn);
class an {
  _lock() {
    return m(!Oe.global), ++this._reculock, 1 !== this._reculock || Oe.global || (Oe.lockOwnerFor = this), this;
  }
  _unlock() {
    if (m(!Oe.global), 0 == --this._reculock) for (Oe.global || (Oe.lockOwnerFor = null); this._blockedFuncs.length > 0 && !this._locked();) {
      var e = this._blockedFuncs.shift();
      try {
        at(e[1], e[0]);
      } catch (e) {}
    }
    return this;
  }
  _locked() {
    return this._reculock && Oe.lockOwnerFor !== this;
  }
  create(e) {
    if (!this.mode) return this;
    const t = this.db.idbdb,
      n = this.db._state.dbOpenError;
    if (m(!this.idbtrans), !e && !t) switch (n && n.name) {
      case "DatabaseClosedError":
        throw new X.DatabaseClosed(n);
      case "MissingAPIError":
        throw new X.MissingAPI(n.message, n);
      default:
        throw new X.OpenFailed(n);
    }
    if (!this.active) throw new X.TransactionInactive();
    return m(null === this._completion._state), (e = this.idbtrans = e || (this.db.core ? this.db.core.transaction(this.storeNames, this.mode, {
      durability: this.chromeTransactionDurability
    }) : t.transaction(this.storeNames, this.mode, {
      durability: this.chromeTransactionDurability
    }))).onerror = Ye(t => {
      nn(t), this._reject(e.error);
    }), e.onabort = Ye(t => {
      nn(t), this.active && this._reject(new X.Abort(e.error)), this.active = !1, this.on("abort").fire(t);
    }), e.oncomplete = Ye(() => {
      this.active = !1, this._resolve(), "mutatedParts" in e && on.storagemutated.fire(e.mutatedParts);
    }), this;
  }
  _promise(e, t, n) {
    if ("readwrite" === e && "readwrite" !== this.mode) return ft(new X.ReadOnly("Transaction is readonly"));
    if (!this.active) return ft(new X.TransactionInactive());
    if (this._locked()) return new je((r, s) => {
      this._blockedFuncs.push([() => {
        this._promise(e, t, n).then(r, s);
      }, Oe]);
    });
    if (n) return Ze(() => {
      var e = new je((e, n) => {
        this._lock();
        const r = t(e, n, this);
        r && r.then && r.then(e, n);
      });
      return e.finally(() => this._unlock()), e._lib = !0, e;
    });
    var r = new je((e, n) => {
      var r = t(e, n, this);
      r && r.then && r.then(e, n);
    });
    return r._lib = !0, r;
  }
  _root() {
    return this.parent ? this.parent._root() : this;
  }
  waitFor(e) {
    var t = this._root();
    const n = je.resolve(e);
    if (t._waitingFor) t._waitingFor = t._waitingFor.then(() => n);else {
      t._waitingFor = n, t._waitingQueue = [];
      var r = t.idbtrans.objectStore(t.storeNames[0]);
      !function e() {
        for (++t._spinCount; t._waitingQueue.length;) t._waitingQueue.shift()();
        t._waitingFor && (r.get(-1 / 0).onsuccess = e);
      }();
    }
    var s = t._waitingFor;
    return new je((e, r) => {
      n.then(n => t._waitingQueue.push(Ye(e.bind(null, n))), e => t._waitingQueue.push(Ye(r.bind(null, e)))).finally(() => {
        t._waitingFor === s && (t._waitingFor = null);
      });
    });
  }
  abort() {
    this.active && (this.active = !1, this.idbtrans && this.idbtrans.abort(), this._reject(new X.Abort()));
  }
  table(e) {
    const t = this._memoizedTables || (this._memoizedTables = {});
    if (o(t, e)) return t[e];
    const n = this.schema[e];
    if (!n) throw new X.NotFound("Table " + e + " not part of transaction");
    const r = new this.db.Table(e, n, this);
    return r.core = this.db.core.table(e), t[e] = r, r;
  }
}
function un(e, t, n, r, s, i, o) {
  return {
    name: e,
    keyPath: t,
    unique: n,
    multi: r,
    auto: s,
    compound: i,
    src: (n && !o ? "&" : "") + (r ? "*" : "") + (s ? "++" : "") + ln(t)
  };
}
function ln(e) {
  return "string" == typeof e ? e : e ? "[" + [].join.call(e, "+") + "]" : "";
}
function cn(e, t, n) {
  return {
    name: e,
    primKey: t,
    indexes: n,
    mappedClass: null,
    idxByName: g(n, e => [e.name, e])
  };
}
let hn = e => {
  try {
    return e.only([[]]), hn = () => [[]], [[]];
  } catch (e) {
    return hn = () => mt, mt;
  }
};
function dn(e) {
  return null == e ? () => {} : "string" == typeof e ? function (e) {
    const t = e.split(".");
    return 1 === t.length ? t => t[e] : t => b(t, e);
  }(e) : t => b(t, e);
}
function fn(e) {
  return [].slice.call(e);
}
let pn = 0;
function yn(e) {
  return null == e ? ":id" : "string" == typeof e ? e : `[${e.join("+")}]`;
}
function mn(e, t, r) {
  function s(e) {
    if (3 === e.type) return null;
    if (4 === e.type) throw new Error("Cannot convert never type to IDBKeyRange");
    const {
      lower: n,
      upper: r,
      lowerOpen: s,
      upperOpen: i
    } = e;
    return void 0 === n ? void 0 === r ? null : t.upperBound(r, !!i) : void 0 === r ? t.lowerBound(n, !!s) : t.bound(n, r, !!s, !!i);
  }
  const {
      schema: i,
      hasGetAll: o
    } = function (e, t) {
      const r = fn(e.objectStoreNames);
      return {
        schema: {
          name: e.name,
          tables: r.map(e => t.objectStore(e)).map(e => {
            const {
                keyPath: t,
                autoIncrement: r
              } = e,
              s = n(t),
              i = null == t,
              o = {},
              a = {
                name: e.name,
                primaryKey: {
                  name: null,
                  isPrimaryKey: !0,
                  outbound: i,
                  compound: s,
                  keyPath: t,
                  autoIncrement: r,
                  unique: !0,
                  extractKey: dn(t)
                },
                indexes: fn(e.indexNames).map(t => e.index(t)).map(e => {
                  const {
                      name: t,
                      unique: r,
                      multiEntry: s,
                      keyPath: i
                    } = e,
                    a = {
                      name: t,
                      compound: n(i),
                      keyPath: i,
                      unique: r,
                      multiEntry: s,
                      extractKey: dn(i)
                    };
                  return o[yn(i)] = a, a;
                }),
                getIndexByKeyPath: e => o[yn(e)]
              };
            return o[":id"] = a.primaryKey, null != t && (o[yn(t)] = a.primaryKey), a;
          })
        },
        hasGetAll: r.length > 0 && "getAll" in t.objectStore(r[0]) && !("undefined" != typeof navigator && /Safari/.test(navigator.userAgent) && !/(Chrome\/|Edge\/)/.test(navigator.userAgent) && [].concat(navigator.userAgent.match(/Safari\/(\d*)/))[1] < 604)
      };
    }(e, r),
    a = i.tables.map(e => function (e) {
      const t = e.name;
      return {
        name: t,
        schema: e,
        mutate: function ({
          trans: e,
          type: n,
          keys: r,
          values: i,
          range: o
        }) {
          return new Promise((a, u) => {
            a = Ye(a);
            const l = e.objectStore(t),
              c = null == l.keyPath,
              h = "put" === n || "add" === n;
            if (!h && "delete" !== n && "deleteRange" !== n) throw new Error("Invalid operation type: " + n);
            const {
              length: d
            } = r || i || {
              length: 1
            };
            if (r && i && r.length !== i.length) throw new Error("Given keys array must have same length as given values array.");
            if (0 === d) return a({
              numFailures: 0,
              failures: {},
              results: [],
              lastResult: void 0
            });
            let f;
            const p = [],
              y = [];
            let m = 0;
            const v = e => {
              ++m, nn(e);
            };
            if ("deleteRange" === n) {
              if (4 === o.type) return a({
                numFailures: m,
                failures: y,
                results: [],
                lastResult: void 0
              });
              3 === o.type ? p.push(f = l.clear()) : p.push(f = l.delete(s(o)));
            } else {
              const [e, t] = h ? c ? [i, r] : [i, null] : [r, null];
              if (h) for (let r = 0; r < d; ++r) p.push(f = t && void 0 !== t[r] ? l[n](e[r], t[r]) : l[n](e[r])), f.onerror = v;else for (let t = 0; t < d; ++t) p.push(f = l[n](e[t])), f.onerror = v;
            }
            const g = e => {
              const t = e.target.result;
              p.forEach((e, t) => null != e.error && (y[t] = e.error)), a({
                numFailures: m,
                failures: y,
                results: "delete" === n ? r : p.map(e => e.result),
                lastResult: t
              });
            };
            f.onerror = e => {
              v(e), g(e);
            }, f.onsuccess = g;
          });
        },
        getMany: ({
          trans: e,
          keys: n
        }) => new Promise((r, s) => {
          r = Ye(r);
          const i = e.objectStore(t),
            o = n.length,
            a = new Array(o);
          let u,
            l = 0,
            c = 0;
          const h = e => {
              const t = e.target;
              a[t._pos] = t.result, ++c === l && r(a);
            },
            d = tn(s);
          for (let e = 0; e < o; ++e) null != n[e] && (u = i.get(n[e]), u._pos = e, u.onsuccess = h, u.onerror = d, ++l);
          0 === l && r(a);
        }),
        get: ({
          trans: e,
          key: n
        }) => new Promise((r, s) => {
          r = Ye(r);
          const i = e.objectStore(t).get(n);
          i.onsuccess = e => r(e.target.result), i.onerror = tn(s);
        }),
        query: function (e) {
          return n => new Promise((r, i) => {
            r = Ye(r);
            const {
                trans: o,
                values: a,
                limit: u,
                query: l
              } = n,
              c = u === 1 / 0 ? void 0 : u,
              {
                index: h,
                range: d
              } = l,
              f = o.objectStore(t),
              p = h.isPrimaryKey ? f : f.index(h.name),
              y = s(d);
            if (0 === u) return r({
              result: []
            });
            if (e) {
              const e = a ? p.getAll(y, c) : p.getAllKeys(y, c);
              e.onsuccess = e => r({
                result: e.target.result
              }), e.onerror = tn(i);
            } else {
              let e = 0;
              const t = a || !("openKeyCursor" in p) ? p.openCursor(y) : p.openKeyCursor(y),
                n = [];
              t.onsuccess = s => {
                const i = t.result;
                return i ? (n.push(a ? i.value : i.primaryKey), ++e === u ? r({
                  result: n
                }) : void i.continue()) : r({
                  result: n
                });
              }, t.onerror = tn(i);
            }
          });
        }(o),
        openCursor: function ({
          trans: e,
          values: n,
          query: r,
          reverse: i,
          unique: o
        }) {
          return new Promise((a, u) => {
            a = Ye(a);
            const {
                index: l,
                range: c
              } = r,
              h = e.objectStore(t),
              d = l.isPrimaryKey ? h : h.index(l.name),
              f = i ? o ? "prevunique" : "prev" : o ? "nextunique" : "next",
              p = n || !("openKeyCursor" in d) ? d.openCursor(s(c), f) : d.openKeyCursor(s(c), f);
            p.onerror = tn(u), p.onsuccess = Ye(t => {
              const n = p.result;
              if (!n) return void a(null);
              n.___id = ++pn, n.done = !1;
              const r = n.continue.bind(n);
              let s = n.continuePrimaryKey;
              s && (s = s.bind(n));
              const i = n.advance.bind(n),
                o = () => {
                  throw new Error("Cursor not stopped");
                };
              n.trans = e, n.stop = n.continue = n.continuePrimaryKey = n.advance = () => {
                throw new Error("Cursor not started");
              }, n.fail = Ye(u), n.next = function () {
                let e = 1;
                return this.start(() => e-- ? this.continue() : this.stop()).then(() => this);
              }, n.start = e => {
                const t = new Promise((e, t) => {
                    e = Ye(e), p.onerror = tn(t), n.fail = t, n.stop = t => {
                      n.stop = n.continue = n.continuePrimaryKey = n.advance = o, e(t);
                    };
                  }),
                  a = () => {
                    if (p.result) try {
                      e();
                    } catch (e) {
                      n.fail(e);
                    } else n.done = !0, n.start = () => {
                      throw new Error("Cursor behind last entry");
                    }, n.stop();
                  };
                return p.onsuccess = Ye(e => {
                  p.onsuccess = a, a();
                }), n.continue = r, n.continuePrimaryKey = s, n.advance = i, a(), t;
              }, a(n);
            }, u);
          });
        },
        count({
          query: e,
          trans: n
        }) {
          const {
            index: r,
            range: i
          } = e;
          return new Promise((e, o) => {
            const a = n.objectStore(t),
              u = r.isPrimaryKey ? a : a.index(r.name),
              l = s(i),
              c = l ? u.count(l) : u.count();
            c.onsuccess = Ye(t => e(t.target.result)), c.onerror = tn(o);
          });
        }
      };
    }(e)),
    u = {};
  return a.forEach(e => u[e.name] = e), {
    stack: "dbcore",
    transaction: e.transaction.bind(e),
    table(e) {
      if (!u[e]) throw new Error(`Table '${e}' not found`);
      return u[e];
    },
    MIN_KEY: -1 / 0,
    MAX_KEY: hn(t),
    schema: i
  };
}
function vn({
  _novip: e
}, t) {
  const n = t.db,
    r = function (e, t, {
      IDBKeyRange: n,
      indexedDB: r
    }, s) {
      const i = function (e, t) {
        return t.reduce((e, {
          create: t
        }) => ({
          ...e,
          ...t(e)
        }), e);
      }(mn(t, n, s), e.dbcore);
      return {
        dbcore: i
      };
    }(e._middlewares, n, e._deps, t);
  e.core = r.dbcore, e.tables.forEach(t => {
    const n = t.name;
    e.core.schema.tables.some(e => e.name === n) && (t.core = e.core.table(n), e[n] instanceof e.Table && (e[n].core = t.core));
  });
}
function gn({
  _novip: e
}, t, n, r) {
  n.forEach(n => {
    const s = r[n];
    t.forEach(t => {
      const r = d(t, n);
      (!r || "value" in r && void 0 === r.value) && (t === e.Transaction.prototype || t instanceof e.Transaction ? l(t, n, {
        get() {
          return this.table(n);
        },
        set(e) {
          u(this, n, {
            value: e,
            writable: !0,
            configurable: !0,
            enumerable: !0
          });
        }
      }) : t[n] = new e.Table(n, s));
    });
  });
}
function bn({
  _novip: e
}, t) {
  t.forEach(t => {
    for (let n in t) t[n] instanceof e.Table && delete t[n];
  });
}
function _n(e, t) {
  return e._cfg.version - t._cfg.version;
}
function wn(e, n, r, s) {
  const i = e._dbSchema,
    o = e._createTransaction("readwrite", e._storeNames, i);
  o.create(r), o._completion.catch(s);
  const a = o._reject.bind(o),
    u = Oe.transless || Oe;
  Ze(() => {
    Oe.trans = o, Oe.transless = u, 0 === n ? (t(i).forEach(e => {
      kn(r, e, i[e].primKey, i[e].indexes);
    }), vn(e, r), je.follow(() => e.on.populate.fire(o)).catch(a)) : function ({
      _novip: e
    }, n, r, s) {
      const i = [],
        o = e._versions;
      let a = e._dbSchema = Pn(e, e.idbdb, s),
        u = !1;
      const l = o.filter(e => e._cfg.version >= n);
      function c() {
        return i.length ? je.resolve(i.shift()(r.idbtrans)).then(c) : je.resolve();
      }
      return l.forEach(o => {
        i.push(() => {
          const i = a,
            l = o._cfg.dbschema;
          Kn(e, i, s), Kn(e, l, s), a = e._dbSchema = l;
          const c = xn(i, l);
          c.add.forEach(e => {
            kn(s, e[0], e[1].primKey, e[1].indexes);
          }), c.change.forEach(e => {
            if (e.recreate) throw new X.Upgrade("Not yet support for changing primary key");
            {
              const t = s.objectStore(e.name);
              e.add.forEach(e => En(t, e)), e.change.forEach(e => {
                t.deleteIndex(e.name), En(t, e);
              }), e.del.forEach(e => t.deleteIndex(e));
            }
          });
          const h = o._cfg.contentUpgrade;
          if (h && o._cfg.version > n) {
            vn(e, s), r._memoizedTables = {}, u = !0;
            let n = w(l);
            c.del.forEach(e => {
              n[e] = i[e];
            }), bn(e, [e.Transaction.prototype]), gn(e, [e.Transaction.prototype], t(n), n), r.schema = n;
            const o = T(h);
            let a;
            o && et();
            const d = je.follow(() => {
              if (a = h(r), a && o) {
                var e = tt.bind(null, null);
                a.then(e, e);
              }
            });
            return a && "function" == typeof a.then ? je.resolve(a) : d.then(() => a);
          }
        }), i.push(t => {
          if (!u || !xt) {
            !function (e, t) {
              [].slice.call(t.db.objectStoreNames).forEach(n => null == e[n] && t.db.deleteObjectStore(n));
            }(o._cfg.dbschema, t);
          }
          bn(e, [e.Transaction.prototype]), gn(e, [e.Transaction.prototype], e._storeNames, e._dbSchema), r.schema = e._dbSchema;
        });
      }), c().then(() => {
        var e, n;
        n = s, t(e = a).forEach(t => {
          n.db.objectStoreNames.contains(t) || kn(n, t, e[t].primKey, e[t].indexes);
        });
      });
    }(e, n, o, r).catch(a);
  });
}
function xn(e, t) {
  const n = {
    del: [],
    add: [],
    change: []
  };
  let r;
  for (r in e) t[r] || n.del.push(r);
  for (r in t) {
    const s = e[r],
      i = t[r];
    if (s) {
      const e = {
        name: r,
        def: i,
        recreate: !1,
        del: [],
        add: [],
        change: []
      };
      if ("" + (s.primKey.keyPath || "") != "" + (i.primKey.keyPath || "") || s.primKey.auto !== i.primKey.auto && !wt) e.recreate = !0, n.change.push(e);else {
        const t = s.idxByName,
          r = i.idxByName;
        let o;
        for (o in t) r[o] || e.del.push(o);
        for (o in r) {
          const n = t[o],
            s = r[o];
          n ? n.src !== s.src && e.change.push(s) : e.add.push(s);
        }
        (e.del.length > 0 || e.add.length > 0 || e.change.length > 0) && n.change.push(e);
      }
    } else n.add.push([r, i]);
  }
  return n;
}
function kn(e, t, n, r) {
  const s = e.db.createObjectStore(t, n.keyPath ? {
    keyPath: n.keyPath,
    autoIncrement: n.auto
  } : {
    autoIncrement: n.auto
  });
  return r.forEach(e => En(s, e)), s;
}
function En(e, t) {
  e.createIndex(t.name, t.keyPath, {
    unique: t.unique,
    multiEntry: t.multi
  });
}
function Pn(e, t, n) {
  const r = {};
  return p(t.objectStoreNames, 0).forEach(e => {
    const t = n.objectStore(e);
    let s = t.keyPath;
    const i = un(ln(s), s || "", !1, !1, !!t.autoIncrement, s && "string" != typeof s, !0),
      o = [];
    for (let e = 0; e < t.indexNames.length; ++e) {
      const n = t.index(t.indexNames[e]);
      s = n.keyPath;
      var a = un(n.name, s, !!n.unique, !!n.multiEntry, !1, s && "string" != typeof s, !1);
      o.push(a);
    }
    r[e] = cn(e, i, o);
  }), r;
}
function Kn({
  _novip: t
}, n, r) {
  const s = r.db.objectStoreNames;
  for (let e = 0; e < s.length; ++e) {
    const i = s[e],
      o = r.objectStore(i);
    t._hasGetAll = "getAll" in o;
    for (let e = 0; e < o.indexNames.length; ++e) {
      const t = o.indexNames[e],
        r = o.index(t).keyPath,
        s = "string" == typeof r ? r : "[" + p(r).join("+") + "]";
      if (n[i]) {
        const e = n[i].idxByName[s];
        e && (e.name = t, delete n[i].idxByName[s], n[i].idxByName[t] = e);
      }
    }
  }
  "undefined" != typeof navigator && /Safari/.test(navigator.userAgent) && !/(Chrome\/|Edge\/)/.test(navigator.userAgent) && e.WorkerGlobalScope && e instanceof e.WorkerGlobalScope && [].concat(navigator.userAgent.match(/Safari\/(\d*)/))[1] < 604 && (t._hasGetAll = !1);
}
class On {
  _parseStoresSpec(e, r) {
    t(e).forEach(t => {
      if (null !== e[t]) {
        var s = e[t].split(",").map((e, t) => {
            const r = (e = e.trim()).replace(/([&*]|\+\+)/g, ""),
              s = /^\[/.test(r) ? r.match(/^\[(.*)\]$/)[1].split("+") : r;
            return un(r, s || null, /\&/.test(e), /\*/.test(e), /\+\+/.test(e), n(s), 0 === t);
          }),
          i = s.shift();
        if (i.multi) throw new X.Schema("Primary key cannot be multi-valued");
        s.forEach(e => {
          if (e.auto) throw new X.Schema("Only primary key can be marked as autoIncrement (++)");
          if (!e.keyPath) throw new X.Schema("Index must have a name and cannot be an empty string");
        }), r[t] = cn(t, i, s);
      }
    });
  }
  stores(e) {
    const n = this.db;
    this._cfg.storesSource = this._cfg.storesSource ? r(this._cfg.storesSource, e) : e;
    const s = n._versions,
      i = {};
    let o = {};
    return s.forEach(e => {
      r(i, e._cfg.storesSource), o = e._cfg.dbschema = {}, e._parseStoresSpec(i, o);
    }), n._dbSchema = o, bn(n, [n._allTables, n, n.Transaction.prototype]), gn(n, [n._allTables, n, n.Transaction.prototype, this._cfg.tables], t(o), o), n._storeNames = t(o), this;
  }
  upgrade(e) {
    return this._cfg.contentUpgrade = ue(this._cfg.contentUpgrade || ee, e), this;
  }
}
function Sn(e, t) {
  let n = e._dbNamesDB;
  return n || (n = e._dbNamesDB = new Xn(Pt, {
    addons: [],
    indexedDB: e,
    IDBKeyRange: t
  }), n.version(1).stores({
    dbnames: "name"
  })), n.table("dbnames");
}
function An(e) {
  return e && "function" == typeof e.databases;
}
function Cn(e) {
  return Ze(function () {
    return Oe.letThrough = !0, e();
  });
}
function jn() {
  var e;
  return !navigator.userAgentData && /Safari\//.test(navigator.userAgent) && !/Chrom(e|ium)\//.test(navigator.userAgent) && indexedDB.databases ? new Promise(function (t) {
    var n = function () {
      return indexedDB.databases().finally(t);
    };
    e = setInterval(n, 100), n();
  }).finally(function () {
    return clearInterval(e);
  }) : Promise.resolve();
}
function Dn(e) {
  const n = e._state,
    {
      indexedDB: r
    } = e._deps;
  if (n.isBeingOpened || e.idbdb) return n.dbReadyPromise.then(() => n.dbOpenError ? ft(n.dbOpenError) : e);
  R && (n.openCanceller._stackHolder = q()), n.isBeingOpened = !0, n.dbOpenError = null, n.openComplete = !1;
  const s = n.openCanceller;
  function i() {
    if (n.openCanceller !== s) throw new X.DatabaseClosed("db.open() was cancelled");
  }
  let o = n.dbReadyResolve,
    a = null,
    u = !1;
  const l = () => new je((s, o) => {
    if (i(), !r) throw new X.MissingAPI();
    const l = e.name,
      c = n.autoSchema ? r.open(l) : r.open(l, Math.round(10 * e.verno));
    if (!c) throw new X.MissingAPI();
    c.onerror = tn(o), c.onblocked = Ye(e._fireOnBlocked), c.onupgradeneeded = Ye(t => {
      if (a = c.transaction, n.autoSchema && !e._options.allowEmptyDB) {
        c.onerror = nn, a.abort(), c.result.close();
        const e = r.deleteDatabase(l);
        e.onsuccess = e.onerror = Ye(() => {
          o(new X.NoSuchDatabase(`Database ${l} doesnt exist`));
        });
      } else {
        a.onerror = tn(o);
        var s = t.oldVersion > Math.pow(2, 62) ? 0 : t.oldVersion;
        u = s < 1, e._novip.idbdb = c.result, wn(e, s / 10, a, o);
      }
    }, o), c.onsuccess = Ye(() => {
      a = null;
      const r = e._novip.idbdb = c.result,
        i = p(r.objectStoreNames);
      if (i.length > 0) try {
        const s = r.transaction(1 === (o = i).length ? o[0] : o, "readonly");
        n.autoSchema ? function ({
          _novip: e
        }, n, r) {
          e.verno = n.version / 10;
          const s = e._dbSchema = Pn(0, n, r);
          e._storeNames = p(n.objectStoreNames, 0), gn(e, [e._allTables], t(s), s);
        }(e, r, s) : (Kn(e, e._dbSchema, s), function (e, t) {
          const n = xn(Pn(0, e.idbdb, t), e._dbSchema);
          return !(n.add.length || n.change.some(e => e.add.length || e.change.length));
        }(e, s) || console.warn("Dexie SchemaDiff: Schema was extended without increasing the number passed to db.version(). Some queries may fail.")), vn(e, s);
      } catch (e) {}
      var o;
      _t.push(e), r.onversionchange = Ye(t => {
        n.vcFired = !0, e.on("versionchange").fire(t);
      }), r.onclose = Ye(t => {
        e.on("close").fire(t);
      }), u && function ({
        indexedDB: e,
        IDBKeyRange: t
      }, n) {
        !An(e) && n !== Pt && Sn(e, t).put({
          name: n
        }).catch(ee);
      }(e._deps, l), s();
    }, o);
  }).catch(e => e && "UnknownError" === e.name && n.PR1398_maxLoop > 0 ? (n.PR1398_maxLoop--, console.warn("Dexie: Workaround for Chrome UnknownError on open()"), l()) : je.reject(e));
  return je.race([s, ("undefined" == typeof navigator ? je.resolve() : jn()).then(l)]).then(() => (i(), n.onReadyBeingFired = [], je.resolve(Cn(() => e.on.ready.fire(e.vip))).then(function t() {
    if (n.onReadyBeingFired.length > 0) {
      let r = n.onReadyBeingFired.reduce(ue, ee);
      return n.onReadyBeingFired = [], je.resolve(Cn(() => r(e.vip))).then(t);
    }
  }))).finally(() => {
    n.onReadyBeingFired = null, n.isBeingOpened = !1;
  }).then(() => e).catch(t => {
    n.dbOpenError = t;
    try {
      a && a.abort();
    } catch (e) {}
    return s === n.openCanceller && e._close(), ft(t);
  }).finally(() => {
    n.openComplete = !0, o();
  });
}
function In(e) {
  var t = t => e.next(t),
    r = i(t),
    s = i(t => e.throw(t));
  function i(e) {
    return t => {
      var i = e(t),
        o = i.value;
      return i.done ? o : o && "function" == typeof o.then ? o.then(r, s) : n(o) ? Promise.all(o).then(r, s) : r(o);
    };
  }
  return i(t)();
}
function Bn(e, t, n) {
  var r = arguments.length;
  if (r < 2) throw new X.InvalidArgument("Too few arguments");
  for (var s = new Array(r - 1); --r;) s[r - 1] = arguments[r];
  return n = s.pop(), [e, k(s), n];
}
function Tn(e, t, n, r, s) {
  return je.resolve().then(() => {
    const i = Oe.transless || Oe,
      o = e._createTransaction(t, n, e._dbSchema, r),
      a = {
        trans: o,
        transless: i
      };
    if (r) o.idbtrans = r.idbtrans;else try {
      o.create(), e._state.PR1398_maxLoop = 3;
    } catch (r) {
      return r.name === H.InvalidState && e.isOpen() && --e._state.PR1398_maxLoop > 0 ? (console.warn("Dexie: Need to reopen db"), e._close(), e.open().then(() => Tn(e, t, n, null, s))) : ft(r);
    }
    const u = T(s);
    let l;
    u && et();
    const c = je.follow(() => {
      if (l = s.call(o, o), l) if (u) {
        var e = tt.bind(null, null);
        l.then(e, e);
      } else "function" == typeof l.next && "function" == typeof l.throw && (l = In(l));
    }, a);
    return (l && "function" == typeof l.then ? je.resolve(l).then(e => o.active ? e : ft(new X.PrematureCommit("Transaction committed too early. See http://bit.ly/2kdckMn"))) : c.then(() => l)).then(e => (r && o._resolve(), o._completion.then(() => e))).catch(e => (o._reject(e), ft(e)));
  });
}
function Rn(e, t, r) {
  const s = n(e) ? e.slice() : [e];
  for (let e = 0; e < r; ++e) s.push(t);
  return s;
}
const Fn = {
  stack: "dbcore",
  name: "VirtualIndexMiddleware",
  level: 1,
  create: function (e) {
    return {
      ...e,
      table(t) {
        const n = e.table(t),
          {
            schema: r
          } = n,
          s = {},
          i = [];
        function o(e, t, n) {
          const r = yn(e),
            a = s[r] = s[r] || [],
            u = null == e ? 0 : "string" == typeof e ? 1 : e.length,
            l = t > 0,
            c = {
              ...n,
              isVirtual: l,
              keyTail: t,
              keyLength: u,
              extractKey: dn(e),
              unique: !l && n.unique
            };
          if (a.push(c), c.isPrimaryKey || i.push(c), u > 1) {
            o(2 === u ? e[0] : e.slice(0, u - 1), t + 1, n);
          }
          return a.sort((e, t) => e.keyTail - t.keyTail), c;
        }
        const a = o(r.primaryKey.keyPath, 0, r.primaryKey);
        s[":id"] = [a];
        for (const e of r.indexes) o(e.keyPath, 0, e);
        function u(t) {
          const n = t.query.index;
          return n.isVirtual ? {
            ...t,
            query: {
              index: n,
              range: (r = t.query.range, s = n.keyTail, {
                type: 1 === r.type ? 2 : r.type,
                lower: Rn(r.lower, r.lowerOpen ? e.MAX_KEY : e.MIN_KEY, s),
                lowerOpen: !0,
                upper: Rn(r.upper, r.upperOpen ? e.MIN_KEY : e.MAX_KEY, s),
                upperOpen: !0
              })
            }
          } : t;
          var r, s;
        }
        const l = {
          ...n,
          schema: {
            ...r,
            primaryKey: a,
            indexes: i,
            getIndexByKeyPath: function (e) {
              const t = s[yn(e)];
              return t && t[0];
            }
          },
          count: e => n.count(u(e)),
          query: e => n.query(u(e)),
          openCursor(t) {
            const {
              keyTail: r,
              isVirtual: s,
              keyLength: i
            } = t.query.index;
            if (!s) return n.openCursor(t);
            return n.openCursor(u(t)).then(n => n && function (n) {
              const s = Object.create(n, {
                continue: {
                  value: function (s) {
                    null != s ? n.continue(Rn(s, t.reverse ? e.MAX_KEY : e.MIN_KEY, r)) : t.unique ? n.continue(n.key.slice(0, i).concat(t.reverse ? e.MIN_KEY : e.MAX_KEY, r)) : n.continue();
                  }
                },
                continuePrimaryKey: {
                  value(t, s) {
                    n.continuePrimaryKey(Rn(t, e.MAX_KEY, r), s);
                  }
                },
                primaryKey: {
                  get: () => n.primaryKey
                },
                key: {
                  get() {
                    const e = n.key;
                    return 1 === i ? e[0] : e.slice(0, i);
                  }
                },
                value: {
                  get: () => n.value
                }
              });
              return s;
            }(n));
          }
        };
        return l;
      }
    };
  }
};
function Mn(e, n, r, s) {
  return r = r || {}, s = s || "", t(e).forEach(t => {
    if (o(n, t)) {
      var i = e[t],
        a = n[t];
      if ("object" == typeof i && "object" == typeof a && i && a) {
        const e = C(i);
        e !== C(a) ? r[s + t] = n[t] : "Object" === e ? Mn(i, a, r, s + t + ".") : i !== a && (r[s + t] = n[t]);
      } else i !== a && (r[s + t] = n[t]);
    } else r[s + t] = void 0;
  }), t(n).forEach(t => {
    o(e, t) || (r[s + t] = n[t]);
  }), r;
}
const Nn = {
  stack: "dbcore",
  name: "HooksMiddleware",
  level: 2,
  create: e => ({
    ...e,
    table(t) {
      const n = e.table(t),
        {
          primaryKey: r
        } = n.schema,
        s = {
          ...n,
          mutate(e) {
            const s = Oe.trans,
              {
                deleting: i,
                creating: a,
                updating: u
              } = s.table(t).hook;
            switch (e.type) {
              case "add":
                if (a.fire === ee) break;
                return s._promise("readwrite", () => l(e), !0);
              case "put":
                if (a.fire === ee && u.fire === ee) break;
                return s._promise("readwrite", () => l(e), !0);
              case "delete":
                if (i.fire === ee) break;
                return s._promise("readwrite", () => l(e), !0);
              case "deleteRange":
                if (i.fire === ee) break;
                return s._promise("readwrite", () => function (e) {
                  return c(e.trans, e.range, 1e4);
                }(e), !0);
            }
            return n.mutate(e);
            function l(e) {
              const t = Oe.trans,
                s = e.keys || function (e, t) {
                  return "delete" === t.type ? t.keys : t.keys || t.values.map(e.extractKey);
                }(r, e);
              if (!s) throw new Error("Keys missing");
              return "delete" !== (e = "add" === e.type || "put" === e.type ? {
                ...e,
                keys: s
              } : {
                ...e
              }).type && (e.values = [...e.values]), e.keys && (e.keys = [...e.keys]), function (e, t, n) {
                return "add" === t.type ? Promise.resolve([]) : e.getMany({
                  trans: t.trans,
                  keys: n,
                  cache: "immutable"
                });
              }(n, e, s).then(l => {
                const c = s.map((n, s) => {
                  const c = l[s],
                    h = {
                      onerror: null,
                      onsuccess: null
                    };
                  if ("delete" === e.type) i.fire.call(h, n, c, t);else if ("add" === e.type || void 0 === c) {
                    const i = a.fire.call(h, n, e.values[s], t);
                    null == n && null != i && (n = i, e.keys[s] = n, r.outbound || _(e.values[s], r.keyPath, n));
                  } else {
                    const r = Mn(c, e.values[s]),
                      i = u.fire.call(h, r, n, c, t);
                    if (i) {
                      const t = e.values[s];
                      Object.keys(i).forEach(e => {
                        o(t, e) ? t[e] = i[e] : _(t, e, i[e]);
                      });
                    }
                  }
                  return h;
                });
                return n.mutate(e).then(({
                  failures: t,
                  results: n,
                  numFailures: r,
                  lastResult: i
                }) => {
                  for (let r = 0; r < s.length; ++r) {
                    const i = n ? n[r] : s[r],
                      o = c[r];
                    null == i ? o.onerror && o.onerror(t[r]) : o.onsuccess && o.onsuccess("put" === e.type && l[r] ? e.values[r] : i);
                  }
                  return {
                    failures: t,
                    results: n,
                    numFailures: r,
                    lastResult: i
                  };
                }).catch(e => (c.forEach(t => t.onerror && t.onerror(e)), Promise.reject(e)));
              });
            }
            function c(e, t, s) {
              return n.query({
                trans: e,
                values: !1,
                query: {
                  index: r,
                  range: t
                },
                limit: s
              }).then(({
                result: n
              }) => l({
                type: "delete",
                keys: n,
                trans: e
              }).then(r => r.numFailures > 0 ? Promise.reject(r.failures[0]) : n.length < s ? {
                failures: [],
                numFailures: 0,
                lastResult: void 0
              } : c(e, {
                ...t,
                lower: n[n.length - 1],
                lowerOpen: !0
              }, s)));
            }
          }
        };
      return s;
    }
  })
};
function qn(e, t, n) {
  try {
    if (!t) return null;
    if (t.keys.length < e.length) return null;
    const r = [];
    for (let s = 0, i = 0; s < t.keys.length && i < e.length; ++s) 0 === $t(t.keys[s], e[i]) && (r.push(n ? O(t.values[s]) : t.values[s]), ++i);
    return r.length === e.length ? r : null;
  } catch (e) {
    return null;
  }
}
const $n = {
  stack: "dbcore",
  level: -1,
  create: e => ({
    table: t => {
      const n = e.table(t);
      return {
        ...n,
        getMany: e => {
          if (!e.cache) return n.getMany(e);
          const t = qn(e.keys, e.trans._cache, "clone" === e.cache);
          return t ? je.resolve(t) : n.getMany(e).then(t => (e.trans._cache = {
            keys: e.keys,
            values: "clone" === e.cache ? O(t) : t
          }, t));
        },
        mutate: e => ("add" !== e.type && (e.trans._cache = null), n.mutate(e))
      };
    }
  })
};
function Un(e) {
  return !("from" in e);
}
const Ln = function (e, t) {
  if (!this) {
    const t = new Ln();
    return e && "d" in e && r(t, e), t;
  }
  r(this, arguments.length ? {
    d: 1,
    from: e,
    to: arguments.length > 1 ? t : e
  } : {
    d: 0
  });
};
function Vn(e, t, n) {
  const s = $t(t, n);
  if (isNaN(s)) return;
  if (s > 0) throw RangeError();
  if (Un(e)) return r(e, {
    from: t,
    to: n,
    d: 1
  });
  const i = e.l,
    o = e.r;
  if ($t(n, e.from) < 0) return i ? Vn(i, t, n) : e.l = {
    from: t,
    to: n,
    d: 1,
    l: null,
    r: null
  }, Gn(e);
  if ($t(t, e.to) > 0) return o ? Vn(o, t, n) : e.r = {
    from: t,
    to: n,
    d: 1,
    l: null,
    r: null
  }, Gn(e);
  $t(t, e.from) < 0 && (e.from = t, e.l = null, e.d = o ? o.d + 1 : 1), $t(n, e.to) > 0 && (e.to = n, e.r = null, e.d = e.l ? e.l.d + 1 : 1);
  const a = !e.r;
  i && !e.l && Wn(e, i), o && a && Wn(e, o);
}
function Wn(e, t) {
  Un(t) || function e(t, {
    from: n,
    to: r,
    l: s,
    r: i
  }) {
    Vn(t, n, r), s && e(t, s), i && e(t, i);
  }(e, t);
}
function Yn(e, t) {
  const n = zn(t);
  let r = n.next();
  if (r.done) return !1;
  let s = r.value;
  const i = zn(e);
  let o = i.next(s.from),
    a = o.value;
  for (; !r.done && !o.done;) {
    if ($t(a.from, s.to) <= 0 && $t(a.to, s.from) >= 0) return !0;
    $t(s.from, a.from) < 0 ? s = (r = n.next(a.from)).value : a = (o = i.next(s.from)).value;
  }
  return !1;
}
function zn(e) {
  let t = Un(e) ? null : {
    s: 0,
    n: e
  };
  return {
    next(e) {
      const n = arguments.length > 0;
      for (; t;) switch (t.s) {
        case 0:
          if (t.s = 1, n) for (; t.n.l && $t(e, t.n.from) < 0;) t = {
            up: t,
            n: t.n.l,
            s: 1
          };else for (; t.n.l;) t = {
            up: t,
            n: t.n.l,
            s: 1
          };
        case 1:
          if (t.s = 2, !n || $t(e, t.n.to) <= 0) return {
            value: t.n,
            done: !1
          };
        case 2:
          if (t.n.r) {
            t.s = 3, t = {
              up: t,
              n: t.n.r,
              s: 0
            };
            continue;
          }
        case 3:
          t = t.up;
      }
      return {
        done: !0
      };
    }
  };
}
function Gn(e) {
  var t, n;
  const r = ((null === (t = e.r) || void 0 === t ? void 0 : t.d) || 0) - ((null === (n = e.l) || void 0 === n ? void 0 : n.d) || 0),
    s = r > 1 ? "r" : r < -1 ? "l" : "";
  if (s) {
    const t = "r" === s ? "l" : "r",
      n = {
        ...e
      },
      r = e[s];
    e.from = r.from, e.to = r.to, e[s] = r[s], n[s] = r[t], e[t] = n, n.d = Hn(n);
  }
  e.d = Hn(e);
}
function Hn({
  r: e,
  l: t
}) {
  return (e ? t ? Math.max(e.d, t.d) : e.d : t ? t.d : 0) + 1;
}
a(Ln.prototype, {
  add(e) {
    return Wn(this, e), this;
  },
  addKey(e) {
    return Vn(this, e, e), this;
  },
  addKeys(e) {
    return e.forEach(e => Vn(this, e, e)), this;
  },
  [j]() {
    return zn(this);
  }
});
const Qn = {
  stack: "dbcore",
  level: 0,
  create: e => {
    const r = e.schema.name,
      s = new Ln(e.MIN_KEY, e.MAX_KEY);
    return {
      ...e,
      table: i => {
        const o = e.table(i),
          {
            schema: a
          } = o,
          {
            primaryKey: u
          } = a,
          {
            extractKey: l,
            outbound: c
          } = u,
          h = {
            ...o,
            mutate: e => {
              const t = e.trans,
                u = t.mutatedParts || (t.mutatedParts = {}),
                l = e => {
                  const t = `idb://${r}/${i}/${e}`;
                  return u[t] || (u[t] = new Ln());
                },
                c = l(""),
                h = l(":dels"),
                {
                  type: d
                } = e;
              let [f, p] = "deleteRange" === e.type ? [e.range] : "delete" === e.type ? [e.keys] : e.values.length < 50 ? [[], e.values] : [];
              const y = e.trans._cache;
              return o.mutate(e).then(e => {
                if (n(f)) {
                  "delete" !== d && (f = e.results), c.addKeys(f);
                  const t = qn(f, y);
                  t || "add" === d || h.addKeys(f), (t || p) && function (e, t, r, s) {
                    function i(t) {
                      const i = e(t.name || "");
                      function o(e) {
                        return null != e ? t.extractKey(e) : null;
                      }
                      const a = e => t.multiEntry && n(e) ? e.forEach(e => i.addKey(e)) : i.addKey(e);
                      (r || s).forEach((e, t) => {
                        const n = r && o(r[t]),
                          i = s && o(s[t]);
                        0 !== $t(n, i) && (null != n && a(n), null != i && a(i));
                      });
                    }
                    t.indexes.forEach(i);
                  }(l, a, t, p);
                } else if (f) {
                  const e = {
                    from: f.lower,
                    to: f.upper
                  };
                  h.add(e), c.add(e);
                } else c.add(s), h.add(s), a.indexes.forEach(e => l(e.name).add(s));
                return e;
              });
            }
          },
          d = ({
            query: {
              index: t,
              range: n
            }
          }) => {
            var r, s;
            return [t, new Ln(null !== (r = n.lower) && void 0 !== r ? r : e.MIN_KEY, null !== (s = n.upper) && void 0 !== s ? s : e.MAX_KEY)];
          },
          f = {
            get: e => [u, new Ln(e.key)],
            getMany: e => [u, new Ln().addKeys(e.keys)],
            count: d,
            query: d,
            openCursor: d
          };
        return t(f).forEach(e => {
          h[e] = function (t) {
            const {
              subscr: n
            } = Oe;
            if (n) {
              const a = e => {
                  const t = `idb://${r}/${i}/${e}`;
                  return n[t] || (n[t] = new Ln());
                },
                u = a(""),
                h = a(":dels"),
                [d, p] = f[e](t);
              if (a(d.name || "").add(p), !d.isPrimaryKey) {
                if ("count" !== e) {
                  const n = "query" === e && c && t.values && o.query({
                    ...t,
                    values: !1
                  });
                  return o[e].apply(this, arguments).then(r => {
                    if ("query" === e) {
                      if (c && t.values) return n.then(({
                        result: e
                      }) => (u.addKeys(e), r));
                      const e = t.values ? r.result.map(l) : r.result;
                      t.values ? u.addKeys(e) : h.addKeys(e);
                    } else if ("openCursor" === e) {
                      const e = r,
                        n = t.values;
                      return e && Object.create(e, {
                        key: {
                          get: () => (h.addKey(e.primaryKey), e.key)
                        },
                        primaryKey: {
                          get() {
                            const t = e.primaryKey;
                            return h.addKey(t), t;
                          }
                        },
                        value: {
                          get: () => (n && u.addKey(e.primaryKey), e.value)
                        }
                      });
                    }
                    return r;
                  });
                }
                h.add(s);
              }
            }
            return o[e].apply(this, arguments);
          };
        }), h;
      }
    };
  }
};
class Xn {
  constructor(e, t) {
    this._middlewares = {}, this.verno = 0;
    const n = Xn.dependencies;
    this._options = t = {
      addons: Xn.addons,
      autoOpen: !0,
      indexedDB: n.indexedDB,
      IDBKeyRange: n.IDBKeyRange,
      ...t
    }, this._deps = {
      indexedDB: t.indexedDB,
      IDBKeyRange: t.IDBKeyRange
    };
    const {
      addons: r
    } = t;
    this._dbSchema = {}, this._versions = [], this._storeNames = [], this._allTables = {}, this.idbdb = null, this._novip = this;
    const s = {
      dbOpenError: null,
      isBeingOpened: !1,
      onReadyBeingFired: null,
      openComplete: !1,
      dbReadyResolve: ee,
      dbReadyPromise: null,
      cancelOpen: ee,
      openCanceller: null,
      autoSchema: !0,
      PR1398_maxLoop: 3
    };
    var i;
    s.dbReadyPromise = new je(e => {
      s.dbReadyResolve = e;
    }), s.openCanceller = new je((e, t) => {
      s.cancelOpen = t;
    }), this._state = s, this.name = e, this.on = Dt(this, "populate", "blocked", "versionchange", "close", {
      ready: [ue, ee]
    }), this.on.ready.subscribe = y(this.on.ready.subscribe, e => (t, n) => {
      Xn.vip(() => {
        const r = this._state;
        if (r.openComplete) r.dbOpenError || je.resolve().then(t), n && e(t);else if (r.onReadyBeingFired) r.onReadyBeingFired.push(t), n && e(t);else {
          e(t);
          const r = this;
          n || e(function e() {
            r.on.ready.unsubscribe(t), r.on.ready.unsubscribe(e);
          });
        }
      });
    }), this.Collection = (i = this, It(Vt.prototype, function (e, t) {
      this.db = i;
      let n = At,
        r = null;
      if (t) try {
        n = t();
      } catch (e) {
        r = e;
      }
      const s = e._ctx,
        o = s.table,
        a = o.hook.reading.fire;
      this._ctx = {
        table: o,
        index: s.index,
        isPrimKey: !s.index || o.schema.primKey.keyPath && s.index === o.schema.primKey.name,
        range: n,
        keysOnly: !1,
        dir: "next",
        unique: "",
        algorithm: null,
        filter: null,
        replayFilter: null,
        justLimit: !0,
        isMatch: null,
        offset: 0,
        limit: 1 / 0,
        error: r,
        or: s.or,
        valueMapper: a !== te ? a : null
      };
    })), this.Table = function (e) {
      return It(jt.prototype, function (t, n, r) {
        this.db = e, this._tx = r, this.name = t, this.schema = n, this.hook = e._allTables[t] ? e._allTables[t].hook : Dt(null, {
          creating: [se, ee],
          reading: [ne, te],
          updating: [oe, ee],
          deleting: [ie, ee]
        });
      });
    }(this), this.Transaction = function (e) {
      return It(an.prototype, function (t, n, r, s, i) {
        this.db = e, this.mode = t, this.storeNames = n, this.schema = r, this.chromeTransactionDurability = s, this.idbtrans = null, this.on = Dt(this, "complete", "error", "abort"), this.parent = i || null, this.active = !0, this._reculock = 0, this._blockedFuncs = [], this._resolve = null, this._reject = null, this._waitingFor = null, this._waitingQueue = null, this._spinCount = 0, this._completion = new je((e, t) => {
          this._resolve = e, this._reject = t;
        }), this._completion.then(() => {
          this.active = !1, this.on.complete.fire();
        }, e => {
          var t = this.active;
          return this.active = !1, this.on.error.fire(e), this.parent ? this.parent._reject(e) : t && this.idbtrans && this.idbtrans.abort(), ft(e);
        });
      });
    }(this), this.Version = function (e) {
      return It(On.prototype, function (t) {
        this.db = e, this._cfg = {
          version: t,
          storesSource: null,
          dbschema: {},
          tables: {},
          contentUpgrade: null
        };
      });
    }(this), this.WhereClause = function (e) {
      return It(en.prototype, function (t, n, r) {
        this.db = e, this._ctx = {
          table: t,
          index: ":id" === n ? null : n,
          or: r
        };
        const s = e._deps.indexedDB;
        if (!s) throw new X.MissingAPI();
        this._cmp = this._ascending = s.cmp.bind(s), this._descending = (e, t) => s.cmp(t, e), this._max = (e, t) => s.cmp(e, t) > 0 ? e : t, this._min = (e, t) => s.cmp(e, t) < 0 ? e : t, this._IDBKeyRange = e._deps.IDBKeyRange;
      });
    }(this), this.on("versionchange", e => {
      e.newVersion > 0 ? console.warn(`Another connection wants to upgrade database '${this.name}'. Closing db now to resume the upgrade.`) : console.warn(`Another connection wants to delete database '${this.name}'. Closing db now to resume the delete request.`), this.close();
    }), this.on("blocked", e => {
      !e.newVersion || e.newVersion < e.oldVersion ? console.warn(`Dexie.delete('${this.name}') was blocked`) : console.warn(`Upgrade '${this.name}' blocked by other connection holding version ${e.oldVersion / 10}`);
    }), this._maxKey = hn(t.IDBKeyRange), this._createTransaction = (e, t, n, r) => new this.Transaction(e, t, n, this._options.chromeTransactionDurability, r), this._fireOnBlocked = e => {
      this.on("blocked").fire(e), _t.filter(e => e.name === this.name && e !== this && !e._state.vcFired).map(t => t.on("versionchange").fire(e));
    }, this.use(Fn), this.use(Nn), this.use(Qn), this.use($n), this.vip = Object.create(this, {
      _vip: {
        value: !0
      }
    }), r.forEach(e => e(this));
  }
  version(e) {
    if (isNaN(e) || e < .1) throw new X.Type("Given version is not a positive number");
    if (e = Math.round(10 * e) / 10, this.idbdb || this._state.isBeingOpened) throw new X.Schema("Cannot add version when database is open");
    this.verno = Math.max(this.verno, e);
    const t = this._versions;
    var n = t.filter(t => t._cfg.version === e)[0];
    return n || (n = new this.Version(e), t.push(n), t.sort(_n), n.stores({}), this._state.autoSchema = !1, n);
  }
  _whenReady(e) {
    return this.idbdb && (this._state.openComplete || Oe.letThrough || this._vip) ? e() : new je((e, t) => {
      if (this._state.openComplete) return t(new X.DatabaseClosed(this._state.dbOpenError));
      if (!this._state.isBeingOpened) {
        if (!this._options.autoOpen) return void t(new X.DatabaseClosed());
        this.open().catch(ee);
      }
      this._state.dbReadyPromise.then(e, t);
    }).then(e);
  }
  use({
    stack: e,
    create: t,
    level: n,
    name: r
  }) {
    r && this.unuse({
      stack: e,
      name: r
    });
    const s = this._middlewares[e] || (this._middlewares[e] = []);
    return s.push({
      stack: e,
      create: t,
      level: null == n ? 10 : n,
      name: r
    }), s.sort((e, t) => e.level - t.level), this;
  }
  unuse({
    stack: e,
    name: t,
    create: n
  }) {
    return e && this._middlewares[e] && (this._middlewares[e] = this._middlewares[e].filter(e => n ? e.create !== n : !!t && e.name !== t)), this;
  }
  open() {
    return Dn(this);
  }
  _close() {
    const e = this._state,
      t = _t.indexOf(this);
    if (t >= 0 && _t.splice(t, 1), this.idbdb) {
      try {
        this.idbdb.close();
      } catch (e) {}
      this._novip.idbdb = null;
    }
    e.dbReadyPromise = new je(t => {
      e.dbReadyResolve = t;
    }), e.openCanceller = new je((t, n) => {
      e.cancelOpen = n;
    });
  }
  close() {
    this._close();
    const e = this._state;
    this._options.autoOpen = !1, e.dbOpenError = new X.DatabaseClosed(), e.isBeingOpened && e.cancelOpen(e.dbOpenError);
  }
  delete() {
    const e = arguments.length > 0,
      t = this._state;
    return new je((n, r) => {
      const s = () => {
        this.close();
        var e = this._deps.indexedDB.deleteDatabase(this.name);
        e.onsuccess = Ye(() => {
          !function ({
            indexedDB: e,
            IDBKeyRange: t
          }, n) {
            !An(e) && n !== Pt && Sn(e, t).delete(n).catch(ee);
          }(this._deps, this.name), n();
        }), e.onerror = tn(r), e.onblocked = this._fireOnBlocked;
      };
      if (e) throw new X.InvalidArgument("Arguments not allowed in db.delete()");
      t.isBeingOpened ? t.dbReadyPromise.then(s) : s();
    });
  }
  backendDB() {
    return this.idbdb;
  }
  isOpen() {
    return null !== this.idbdb;
  }
  hasBeenClosed() {
    const e = this._state.dbOpenError;
    return e && "DatabaseClosed" === e.name;
  }
  hasFailed() {
    return null !== this._state.dbOpenError;
  }
  dynamicallyOpened() {
    return this._state.autoSchema;
  }
  get tables() {
    return t(this._allTables).map(e => this._allTables[e]);
  }
  transaction() {
    const e = Bn.apply(this, arguments);
    return this._transaction.apply(this, e);
  }
  _transaction(e, t, n) {
    let r = Oe.trans;
    r && r.db === this && -1 === e.indexOf("!") || (r = null);
    const s = -1 !== e.indexOf("?");
    let i, o;
    e = e.replace("!", "").replace("?", "");
    try {
      if (o = t.map(e => {
        var t = e instanceof this.Table ? e.name : e;
        if ("string" != typeof t) throw new TypeError("Invalid table argument to Dexie.transaction(). Only Table or String are allowed");
        return t;
      }), "r" == e || e === Kt) i = Kt;else {
        if ("rw" != e && e != Ot) throw new X.InvalidArgument("Invalid transaction mode: " + e);
        i = Ot;
      }
      if (r) {
        if (r.mode === Kt && i === Ot) {
          if (!s) throw new X.SubTransaction("Cannot enter a sub-transaction with READWRITE mode when parent transaction is READONLY");
          r = null;
        }
        r && o.forEach(e => {
          if (r && -1 === r.storeNames.indexOf(e)) {
            if (!s) throw new X.SubTransaction("Table " + e + " not included in parent transaction.");
            r = null;
          }
        }), s && r && !r.active && (r = null);
      }
    } catch (e) {
      return r ? r._promise(null, (t, n) => {
        n(e);
      }) : ft(e);
    }
    const a = Tn.bind(null, this, i, o, r, n);
    return r ? r._promise(i, a, "lock") : Oe.trans ? at(Oe.transless, () => this._whenReady(a)) : this._whenReady(a);
  }
  table(e) {
    if (!o(this._allTables, e)) throw new X.InvalidTable(`Table ${e} does not exist`);
    return this._allTables[e];
  }
}
const Jn = "undefined" != typeof Symbol && "observable" in Symbol ? Symbol.observable : "@@observable";
class Zn {
  constructor(e) {
    this._subscribe = e;
  }
  subscribe(e, t, n) {
    return this._subscribe(e && "function" != typeof e ? e : {
      next: e,
      error: t,
      complete: n
    });
  }
  [Jn]() {
    return this;
  }
}
function er(e, n) {
  return t(n).forEach(t => {
    Wn(e[t] || (e[t] = new Ln()), n[t]);
  }), e;
}
function tr(e) {
  let n,
    r = !1;
  const s = new Zn(s => {
    const i = T(e);
    let o = !1,
      a = {},
      u = {};
    const l = {
      get closed() {
        return o;
      },
      unsubscribe: () => {
        o = !0, on.storagemutated.unsubscribe(f);
      }
    };
    s.start && s.start(l);
    let c = !1,
      h = !1;
    function d() {
      return t(u).some(e => a[e] && Yn(a[e], u[e]));
    }
    const f = e => {
        er(a, e), d() && p();
      },
      p = () => {
        if (c || o) return;
        a = {};
        const t = {},
          y = function (t) {
            i && et();
            const n = () => Ze(e, {
                subscr: t,
                trans: null
              }),
              r = Oe.trans ? at(Oe.transless, n) : n();
            return i && r.then(tt, tt), r;
          }(t);
        h || (on(rn, f), h = !0), c = !0, Promise.resolve(y).then(e => {
          r = !0, n = e, c = !1, o || (d() ? p() : (a = {}, u = t, s.next && s.next(e)));
        }, e => {
          c = !1, r = !1, s.error && s.error(e), l.unsubscribe();
        });
      };
    return p(), l;
  });
  return s.hasValue = () => r, s.getValue = () => n, s;
}
let nr;
try {
  nr = {
    indexedDB: e.indexedDB || e.mozIndexedDB || e.webkitIndexedDB || e.msIndexedDB,
    IDBKeyRange: e.IDBKeyRange || e.webkitIDBKeyRange
  };
} catch (e) {
  nr = {
    indexedDB: null,
    IDBKeyRange: null
  };
}
const rr = Xn;
function sr(e) {
  let t = ir;
  try {
    ir = !0, on.storagemutated.fire(e);
  } finally {
    ir = t;
  }
}
a(rr, {
  ...Z,
  delete: e => new rr(e, {
    addons: []
  }).delete(),
  exists: e => new rr(e, {
    addons: []
  }).open().then(e => (e.close(), !0)).catch("NoSuchDatabaseError", () => !1),
  getDatabaseNames(e) {
    try {
      return function ({
        indexedDB: e,
        IDBKeyRange: t
      }) {
        return An(e) ? Promise.resolve(e.databases()).then(e => e.map(e => e.name).filter(e => e !== Pt)) : Sn(e, t).toCollection().primaryKeys();
      }(rr.dependencies).then(e);
    } catch (e) {
      return ft(new X.MissingAPI());
    }
  },
  defineClass: () => function (e) {
    r(this, e);
  },
  ignoreTransaction: e => Oe.trans ? at(Oe.transless, e) : e(),
  vip: Cn,
  async: function (e) {
    return function () {
      try {
        var t = In(e.apply(this, arguments));
        return t && "function" == typeof t.then ? t : je.resolve(t);
      } catch (e) {
        return ft(e);
      }
    };
  },
  spawn: function (e, t, n) {
    try {
      var r = In(e.apply(n, t || []));
      return r && "function" == typeof r.then ? r : je.resolve(r);
    } catch (e) {
      return ft(e);
    }
  },
  currentTransaction: {
    get: () => Oe.trans || null
  },
  waitFor: function (e, t) {
    const n = je.resolve("function" == typeof e ? rr.ignoreTransaction(e) : e).timeout(t || 6e4);
    return Oe.trans ? Oe.trans.waitFor(n) : n;
  },
  Promise: je,
  debug: {
    get: () => R,
    set: e => {
      F(e, "dexie" === e ? () => !0 : Et);
    }
  },
  derive: c,
  extend: r,
  props: a,
  override: y,
  Events: Dt,
  on: on,
  liveQuery: tr,
  extendObservabilitySet: er,
  getByKeyPath: b,
  setByKeyPath: _,
  delByKeyPath: function (e, t) {
    "string" == typeof t ? _(e, t, void 0) : "length" in t && [].map.call(t, function (t) {
      _(e, t, void 0);
    });
  },
  shallowClone: w,
  deepClone: O,
  getObjectDiff: Mn,
  cmp: $t,
  asap: v,
  minKey: vt,
  addons: [],
  connections: _t,
  errnames: H,
  dependencies: nr,
  semVer: yt,
  version: yt.split(".").map(e => parseInt(e)).reduce((e, t, n) => e + t / Math.pow(10, 2 * n))
}), rr.maxKey = hn(rr.dependencies.IDBKeyRange), "undefined" != typeof dispatchEvent && "undefined" != typeof addEventListener && (on(rn, e => {
  if (!ir) {
    let t;
    wt ? (t = document.createEvent("CustomEvent"), t.initCustomEvent(sn, !0, !0, e)) : t = new CustomEvent(sn, {
      detail: e
    }), ir = !0, dispatchEvent(t), ir = !1;
  }
}), addEventListener(sn, ({
  detail: e
}) => {
  ir || sr(e);
}));
let ir = !1;
if ("undefined" != typeof BroadcastChannel) {
  const e = new BroadcastChannel(sn);
  "function" == typeof e.unref && e.unref(), on(rn, t => {
    ir || e.postMessage(t);
  }), e.onmessage = e => {
    e.data && sr(e.data);
  };
} else if ("undefined" != typeof self && "undefined" != typeof navigator) {
  on(rn, e => {
    try {
      ir || ("undefined" != typeof localStorage && localStorage.setItem(sn, JSON.stringify({
        trig: Math.random(),
        changedParts: e
      })), "object" == typeof self.clients && [...self.clients.matchAll({
        includeUncontrolled: !0
      })].forEach(t => t.postMessage({
        type: sn,
        changedParts: e
      })));
    } catch (e) {}
  }), "undefined" != typeof addEventListener && addEventListener("storage", e => {
    if (e.key === sn) {
      const t = JSON.parse(e.newValue);
      t && sr(t.changedParts);
    }
  });
  const e = self.document && navigator.serviceWorker;
  e && e.addEventListener("message", function ({
    data: e
  }) {
    e && e.type === sn && sr(e.changedParts);
  });
}
je.rejectionMapper = function (e, t) {
  if (!e || e instanceof W || e instanceof TypeError || e instanceof SyntaxError || !e.name || !J[e.name]) return e;
  var n = new J[e.name](t || e.message, e);
  return "stack" in e && l(n, "stack", {
    get: function () {
      return this.inner.stack;
    }
  }), n;
}, F(R, Et);
export { Xn as Dexie, Ln as RangeSet, Xn as default, tr as liveQuery, Wn as mergeRanges, Yn as rangesOverlap };
