import { db } from "scripts/db";
import { Patient } from "entities/patient";
import * as MobilDentEntity from "entities/MobilDentEntity";
import * as Sync from "entities/sync";
import { parseNullableInt } from "common";
import { Screening, TransferState } from "entities/screening";
import { Activity } from "../entities/activity";
import { SyncScreening } from "entities/sync";
import { Recommendation } from "../entities/recommendation";
import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class MobilDentApi {
  syncSessionId: Promise<number> | null = null;

  public constructor(
    private httpClient: HttpClient,
    @Inject("BASE_API_URL") private baseUrl: string,
  ) {}

  public async getSyncScreeningActivity(departmentId: number) {
    const syncSessionId = await this.getActiveSyncSessionIdIfExists();
    if (!syncSessionId) {
      return null;
    }
    return await firstValueFrom(
      this.httpClient.get<Sync.SyncScreeningActivity>(this.baseUrl + "SyncActivity/getSyncActivity", {
        params: { syncSessionId, departmentId, activityType: Sync.BinaryActivityType.Screening },
      }),
    );
  }

  public async getSyncEducation(departmentId: number) {
    const syncSessionId = await this.getActiveSyncSessionIdIfExists();
    if (!syncSessionId) {
      return null;
    }
    return await firstValueFrom(
      this.httpClient.get<Sync.SyncEducationActivity>(this.baseUrl + "SyncActivity/getSyncActivity", {
        params: { syncSessionId, departmentId, activityType: Sync.BinaryActivityType.Education },
      }),
    );
  }

  public async getOrCreateActiveSyncSession(): Promise<number> {
    if (this.syncSessionId == null) {
      this.syncSessionId = firstValueFrom(this.httpClient.post<number>(this.baseUrl + "SyncActivity/getOrCreateActiveSyncSession", null)).catch(
        (e) => {
          this.syncSessionId = null;
          throw e;
        },
      );
    }

    const id = await this.syncSessionId;
    if (id == null) {
      this.syncSessionId = null;
      return await this.getOrCreateActiveSyncSession();
    }

    return id;
  }

  public async getActiveSyncSessionIdIfExists(): Promise<number | null> {
    if (this.syncSessionId == null) {
      this.syncSessionId = firstValueFrom(this.httpClient.get<number>(this.baseUrl + "SyncActivity/getActiveSyncSessionIdIfExists")).catch((e) => {
        this.syncSessionId = null;
        throw e;
      });
    }

    return await this.syncSessionId;
  }

  public async postSyncActivity(activity: Sync.SyncActivityData) {
    const syncSessionId = await this.getOrCreateActiveSyncSession();

    const newActivityId = await firstValueFrom(this.httpClient.post<number>(this.baseUrl + "SyncActivity", activity, { params: { syncSessionId } }));
    return newActivityId;
  }

  public async putSyncActivity(activity: Activity) {
    const syncSessionId = await this.getOrCreateActiveSyncSession();

    await firstValueFrom(this.httpClient.put(this.baseUrl + "SyncActivity", activity, { params: { syncSessionId } }));
  }

  public async writeSyncScreening(activityId: number, screening: Sync.SyncScreening) {
    const syncSessionId = await this.getOrCreateActiveSyncSession();

    await firstValueFrom(
      this.httpClient.post(this.baseUrl + "SyncActivity/writeSyncScreening", screening, {
        params: { syncSessionId, activityId },
      }),
    );
  }

  public async loadRecommendations(): Promise<MobilDentEntity.Recommendation[]> {
    return await firstValueFrom(this.httpClient.get<MobilDentEntity.Recommendation[]>(this.baseUrl + "recommendations"));
  }

  public async loadStates(): Promise<MobilDentEntity.CountyCouncil[]> {
    return firstValueFrom(this.httpClient.get<MobilDentEntity.CountyCouncil[]>(this.baseUrl + "CountyCouncils"));
  }

  public async loadAreas(countyCouncilId: number): Promise<MobilDentEntity.ContractArea[]> {
    return firstValueFrom(
      this.httpClient.get<MobilDentEntity.ContractArea[]>(this.baseUrl + "AreasByCountyCouncil", {
        params: { countyCouncilId: countyCouncilId.toString() },
      }),
    );
  }

  public async loadUnits(areaId: number): Promise<MobilDentEntity.Unit[]> {
    return firstValueFrom(this.httpClient.get<MobilDentEntity.Unit[]>(this.baseUrl + "UnitsByArea", { params: { areaId: areaId.toString() } }));
  }

  public async loadDepartments(unitId: number): Promise<MobilDentEntity.Department[]> {
    return firstValueFrom(
      this.httpClient.get<MobilDentEntity.Department[]>(this.baseUrl + "DepartmentsByUnit", { params: { unitId: unitId.toString() } }),
    );
  }

  public async loadSyncScreenings(activityId: number) {
    const syncSessionId = await this.getActiveSyncSessionIdIfExists();
    if (syncSessionId == null) {
      return;
    }

    const screenings = await firstValueFrom(
      this.httpClient.get<SyncScreening[]>(this.baseUrl + "SyncActivity/Screenings", { params: { syncSessionId, activityId } }),
    );
    if (screenings) {
      for (const serverScreening of screenings) {
        const screening: Screening = {} as Screening;
        screening.id = serverScreening.screeningId;
        screening.activityId = serverScreening.activityId;
        screening.patientId = serverScreening.patientId;

        if (serverScreening.syncScreeningInfo) {
          screening.anamnesisAbilityToChew = serverScreening.syncScreeningInfo.anamnesisAbilityToChew;
          screening.anamnesisDryMouth = serverScreening.syncScreeningInfo.anamnesisDryMouth;
          screening.anamnesisDryMouthType = serverScreening.syncScreeningInfo.anamnesisDryMouthType;
          screening.anamnesisMouthHygiene = serverScreening.syncScreeningInfo.anamnesisMouthHygiene;
          screening.anamnesisMouthMembraneChange = serverScreening.syncScreeningInfo.anamnesisMouthMembraneChange;
          screening.anamnesisPain = serverScreening.syncScreeningInfo.anamnesisPain;
          screening.anamnesisRed = serverScreening.syncScreeningInfo.anamnesisRed;
          screening.anamnesisWhite = serverScreening.syncScreeningInfo.anamnesisWhite;
          screening.anamnesisWound = serverScreening.syncScreeningInfo.anamnesisWound;
          screening.assistingStaff = serverScreening.syncScreeningInfo.assistingStaff;
          screening.assistingStaffNotes = serverScreening.syncScreeningInfo.assistingStaffNotes;
          if (screening.assistingStaffNotes) {
            screening.assistingStaffPresent = false;
          } else {
            screening.assistingStaffPresent = true;
          }
          screening.dentistBadFitting = serverScreening.syncScreeningInfo.dentistBadFitting;
          screening.dentistBrokenDenture = serverScreening.syncScreeningInfo.dentistBrokenDenture;
          screening.dentistCaries = serverScreening.syncScreeningInfo.dentistCaries;
          screening.dentistCariesGroup = serverScreening.syncScreeningInfo.dentistCariesGroup;
          screening.dentistCariesTreatmentNeed = serverScreening.syncScreeningInfo.dentistCariesTreatmentNeed;
          screening.dentistClinicPhone = serverScreening.syncScreeningInfo.dentistClinicPhone;
          screening.dentistDentureTreatmentNeed = serverScreening.syncScreeningInfo.dentistDentureTreatmentNeed;
          screening.dentistDispatchableDenture = serverScreening.syncScreeningInfo.dentistDispatchableDenture;
          screening.dentistExamination = serverScreening.syncScreeningInfo.dentistExamination;
          screening.dentistFracture = serverScreening.syncScreeningInfo.dentistFracture;
          screening.dentistLooseBridge = serverScreening.syncScreeningInfo.dentistLooseBridge;
          screening.dentistLostFilling = serverScreening.syncScreeningInfo.dentistLostFilling;
          screening.dentistMouthMembraneChange = serverScreening.syncScreeningInfo.dentistMouthMembraneChange;
          screening.dentistMouthMembraneTreatmentNeed = serverScreening.syncScreeningInfo.dentistMouthMembraneTreatmentNeed;
          screening.dentistName = serverScreening.syncScreeningInfo.dentistName;
          screening.dentistNeed = serverScreening.syncScreeningInfo.dentistNeed;
          screening.dentistNewDenture = serverScreening.syncScreeningInfo.dentistNewDenture;
          screening.dentistDentureExamination = serverScreening.syncScreeningInfo.dentistDentureExamination;
          screening.dentistOtherCause = serverScreening.syncScreeningInfo.dentistOtherCause;
          screening.dentistRed = serverScreening.syncScreeningInfo.dentistRed;
          screening.dentistType = serverScreening.syncScreeningInfo.dentistType;
          screening.dentistUrgent = serverScreening.syncScreeningInfo.dentistUrgent;
          screening.dentistWhite = serverScreening.syncScreeningInfo.dentistWhite;
          screening.dentistWishes = serverScreening.syncScreeningInfo.dentistWishes;
          screening.dentistWound = serverScreening.syncScreeningInfo.dentistWound;
          screening.dentistMouthMembraneExamination = serverScreening.syncScreeningInfo.dentistMouthMembraneExamination;
          screening.getsSupervisionToday = serverScreening.syncScreeningInfo.getsSupervisionToday;
          screening.hygienistClinicPhone = serverScreening.syncScreeningInfo.hygienistClinicPhone;
          screening.hygienistExamination = serverScreening.syncScreeningInfo.hygienistExamination;
          screening.hygienistGingivit = serverScreening.syncScreeningInfo.hygienistGingivit;
          screening.hygienistName = serverScreening.syncScreeningInfo.hygienistName;
          screening.hygienistNeed = serverScreening.syncScreeningInfo.hygienistNeed;
          screening.hygienistNeedUrgent = serverScreening.syncScreeningInfo.hygienistNeedUrgent;
          screening.hygienistOtherCause = serverScreening.syncScreeningInfo.hygienistOtherCause;
          screening.hygienistParodontalTreatment = serverScreening.syncScreeningInfo.hygienistParodontalTreatment;
          screening.hygienistParodontit = serverScreening.syncScreeningInfo.hygienistParodontit;
          screening.hygienistTartar = serverScreening.syncScreeningInfo.hygienistTartar;
          screening.hygienistType = serverScreening.syncScreeningInfo.hygienistType;
          screening.hygienistWishes = serverScreening.syncScreeningInfo.hygienistWishes;
          screening.needsSupervision = serverScreening.syncScreeningInfo.needsSupervision;
          screening.numberOfImplantScrewsUpperJaw = serverScreening.syncScreeningInfo.numberOfImplantScrewsUpperJaw;
          screening.numberOfImplantScrewsLowerJaw = serverScreening.syncScreeningInfo.numberOfImplantScrewsLowerJaw;
          screening.otherRecommendation = serverScreening.syncScreeningInfo.otherRecommendation;
          screening.referral = serverScreening.syncScreeningInfo.referral;
          screening.dailyHelp = serverScreening.syncScreeningInfo.dailyHelp;
        } else {
          screening.dentistOtherCause = "";
          screening.hygienistOtherCause = "";
        }

        let selectedRecommendations = serverScreening.syncScreeningRecommendation;
        let allRecommendations = await db.recommendations.toArray();
        screening.recommendations = allRecommendations;
        if (selectedRecommendations) {
          for (const recommendation of allRecommendations) {
            if (this.containsRecommendationWithText(selectedRecommendations, recommendation.recommendationText)) {
              recommendation.chosen = true; //Set the chosen recommendations to true to show them on the website.
            }
          }
        }

        if (serverScreening.syncDeviationInfo) {
          screening.noThanksDeliveredBy = serverScreening.syncDeviationInfo.deliveredByName;
          screening.noThanksDeliveredByPhoneNumber = serverScreening.syncDeviationInfo.deliveredByPhoneNumber;
          screening.deviationNotes = serverScreening.syncDeviationInfo.notes;
        }

        screening.screeningStatus = serverScreening.screeningStatus;
        screening.transferState = TransferState.SentToServer;

        screening.toothInfos = {};
        for (const j of serverScreening.syncToothInfo ?? []) {
          screening.toothInfos[j.toothNumber] = j.toothStatus;
        }

        screening.jawInfos = serverScreening.syncJawInfo?.map((j) => ({ jaw: j.jaw, jawStatus: j.jawStatus }));

        // For local reporting

        let patientId = serverScreening.patientId;
        let patient = await db.patient.where("id").equals(patientId).first(); //Can only be one patient with that Id.
        screening.firstName = patient?.firstName;
        screening.surName = patient?.surName;
        screening.civicRegNo = patient?.civicRegNo;

        screening.unitId = parseNullableInt(sessionStorage.getItem("unitId"));
        screening.departmentId = parseNullableInt(sessionStorage.getItem("departmentId"));
        screening.unitName = sessionStorage.getItem("unitName");
        screening.departmentName = sessionStorage.getItem("departmentName");
        await db.screening.put(screening);
      }
    }
  }

  private containsRecommendationWithText(recommendations: Recommendation[], text: string) {
    for (let i = 0; i < recommendations.length; i++) {
      if (recommendations[i].recommendationText === text) {
        return true;
      }
    }
    return false;
  }

  public async loadPatients(departmentId: string) {
    let data = await firstValueFrom(
      this.httpClient.get<MobilDentEntity.Patient[]>(this.baseUrl + "PatientsByDepartment", { params: { departmentId: departmentId } }),
    );

    await db.transaction("rw", db.patient, async () => {
      await db.patient.clear();

      let patientsToAdd: Patient[] = [];
      for (const pat of data) {
        let padding = " ";
        if (pat.surName.length < 30) {
          padding = new Array(30 - pat.surName.length).join(" ");
        }

        patientsToAdd.push({
          ...pat,
          nameForSorting: (pat.surName + padding + pat.firstName).toLowerCase(),
        });
      }

      if (patientsToAdd.length > 0) {
        await db.patient.bulkAdd(patientsToAdd);
      }
    });
  }

  public async sendLocalData(localData: string) {
    await firstValueFrom(this.httpClient.post(this.baseUrl + "debug/localData", localData));
  }

  public async getAuthConfig() {
    return firstValueFrom(this.httpClient.get<AuthConfig>(this.baseUrl + "auth/config"));
  }

  public async endSyncSession() {
    const syncSessionId = await this.getActiveSyncSessionIdIfExists();
    if (syncSessionId == null) {
      return;
    }
    await firstValueFrom(this.httpClient.post(this.baseUrl + "SyncActivity/endSyncSession", null, { params: { syncSessionId } }));
    this.syncSessionId = null;
  }
}

export interface AuthConfig {
  clientId: string;
  directoryId: string;
}
